import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { breadcrumbsIntegration, init } from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { MotionConfig } from 'framer-motion'
import { PersistGate } from 'redux-persist/lib/integration/react'

import './i18next-config'

import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import LoadingScreen from './containers/loading/LoadingScreen'
import BrandContextProvider from './context/BrandContextProvider.tsx'
import { persistor, store } from './redux/store'
import App from './App.tsx'

import './style/index.scss'
import './style/animations.scss'
import './style/fontface.scss'
import './style/normalize.scss'

init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    environment: import.meta.env.REACT_APP_ENVIRONMENT,
    sampleRate: import.meta.env.REACT_APP_SENTRY_SAMPLERATE,
    ignoreErrors: [
        'top.GLOBALS',
        'NetworkError',
        'Network Error',
        'Request failed with status code 401',
        'Request failed with status code 404',
        'Request failed with status code 422',
        'Request failed with status code 403',
        'timeout of 0ms exceeded',
        'timeout of 60000ms exceeded',
        'Request aborted',
        "Unexpected token '<'",
        "Cannot read property 'style' of null",
        'Loading CSS chunk',
        "Can't find variable: dpg_snowplow",
        "ReferenceError: Can't find variable: dpg_snowplow",
        'Uncaught ReferenceError: dpg_snowplow is not defined',
        'AxiosError: Request failed with status code 412',
        "SyntaxError: Failed to execute 'querySelector' on 'Document'",
    ],
    denyUrls: [/extensions\//i, /^chrome:\/\//i, /^chrome-extension:\/\//i],
    beforeSend(event, hint) {
        const error = hint.originalException

        if (error && error.message) {
            if (
                error.message.match(/Network Error/i) ||
                error.message.match(/Request failed with status code 401/i) ||
                error.message.match(/Request failed with status code 404/i) ||
                error.message.match(/Request failed with status code 422/i) ||
                error.message.match(/Request failed with status code 403/i) ||
                error.message.match(/timeout of 0ms exceeded/i) ||
                error.message.match(/dpg_snowplow/i) ||
                error.message.match(/msDiscoverChatAvailable/i) ||
                error.message.match(/Request aborted/i) ||
                error.message.match(/AxiosError: Request failed with status code 412/i) ||
                error.message.match(/SyntaxError: Failed to execute 'querySelector' on 'Document'/i) ||
                error.message.match(/Object captured as promise rejection with keys: message, response, status/i)
            ) {
                return null
            }
        }

        return event
    },
    integrations: [
        breadcrumbsIntegration({
            beacon: true,
            console: import.meta.env.REACT_APP_ENVIRONMENT !== 'local',
            dom: true,
            fetch: true,
            history: true,
            sentry: true,
            xhr: true,
        }),
    ],
})

const queryClient = new QueryClient()

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <MotionConfig reducedMotion='never'>
                <HelmetProvider>
                    <BrandContextProvider>
                        <PersistGate
                            loading={<LoadingScreen />}
                            persistor={persistor}>
                            <ErrorBoundary>
                                <Suspense fallback={<LoadingScreen />}>
                                    <App />
                                </Suspense>
                            </ErrorBoundary>
                        </PersistGate>
                    </BrandContextProvider>
                </HelmetProvider>
            </MotionConfig>
        </Provider>
    </QueryClientProvider>,
    document.getElementById('root')
)

// NOSONAR
// registerServiceWorker();
// NOSONAR
// unregister();
