import { FC, useCallback } from 'react'
import { motion } from 'framer-motion'
import QuantitySelector from 'src/components/QuantitySelector/QuantitySelector'

interface IAdditionValueQuantitySelectorProperties {
    onChange: (newAmount: number) => void
    value: number
    range?: { min: number; max: number }
}

const AdditionValueQuantitySelector: FC<IAdditionValueQuantitySelectorProperties> = ({
    range,
    onChange,
    value = 1,
}) => {
    const handleChange = useCallback(
        (amount) => {
            onChange(value + amount)
        },
        [onChange, value]
    )

    return (
        <motion.div
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 100, opacity: 0 }}
            className='addition-quantity'>
            <QuantitySelector
                type='mini'
                amount={Number.isNaN(value) ? 1 : value}
                range={range ?? undefined}
                onQuantityChange={handleChange}
            />
        </motion.div>
    )
}

export default AdditionValueQuantitySelector
