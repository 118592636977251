import { FC } from 'react'
import { motion } from 'framer-motion'
import { staggerFadeInUpChild } from 'src/constants/Animations'

import { ALLERGENS } from './AllergensData'

import './allergens.scss'

interface IAllergenProperties {
    id: string
}

const Allergen: FC<IAllergenProperties> = ({ id }) => {
    const item = ALLERGENS?.find((allergen) => allergen.id === id)

    return item === undefined ? null : (
        <motion.div
            key={id}
            variants={staggerFadeInUpChild}
            className='c-allergen'>
            <div
                className='c-allergen--icon'
                style={{ backgroundColor: item.color ?? undefined }}>
                <img
                    src={`/allergens/${item.icon}.svg`}
                    alt={item.display_name}
                />
            </div>
            <div className='c-allergen--label'>{item.display_name}</div>
        </motion.div>
    )
}

export default Allergen
