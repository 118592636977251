import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FaStar } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { ERROR_MODAL, PROMO_MODAL } from '../../containers/modal/modalTypes'
import { EventContext } from '../../context/EventContextProvider'
import { addToBasket, closeAllModals, openModal } from '../../redux/actions/actionBuilders'

import './promorecommendation.scss'

function PromoRecommendation({ promotion, product }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { basket } = useSelector((state) => state)
    const { event } = useContext(EventContext)

    const addPromotionProductToBasket = useCallback(() => {
        if (product) {
            dispatch(
                addToBasket({
                    ...product,
                    event_id: event.id,
                    eventName: event.name,
                    groupId: undefined,
                    additions: {},
                    has_additions: false,
                    totalAdditionsPrice: 0,
                    amount: promotion.amountToAddToBasket,
                    type: 'promotion',
                    type_reference_id: promotion.id,
                    promotion,
                })
            )
        }
    }, [product])

    const onPromoClick = useCallback(() => {
        if (promotion) {
            let promoFoundInBasket = false
            Object.values(basket?.[event.id]?.products ?? {}).forEach((product) => {
                if (product.promotion) {
                    promoFoundInBasket = true
                }
            })

            if (promoFoundInBasket === true) {
                dispatch(
                    openModal({
                        type: ERROR_MODAL,
                        data: {
                            title: t('modal.promotions.error.only_one_promo_allowed_per_basket.title', 'Whoops!'),
                            message: t(
                                'modal.promotions.error.only_one_promo_allowed_per_basket.body',
                                'Only one promotion is allowed per order!'
                            ),
                        },
                    })
                )
            } else {
                dispatch(
                    openModal({
                        type: PROMO_MODAL,
                        data: {
                            promo: promotion,
                            defaultPromoProductId: product.id,
                            onSuccess: () => {
                                addPromotionProductToBasket()
                                dispatch(closeAllModals())
                            },
                        },
                    })
                )
            }
        }
    }, [promotion, product])

    if (promotion) {
        return (
            <div className='c-promo-recommendation'>
                <div className='c-promo-recommendation-image'>
                    {promotion.coverImage ? (
                        <img
                            loading='lazy'
                            src={promotion.coverImage}
                        />
                    ) : (
                        <div className='c-promo-img-fallback'>
                            <FaStar />
                        </div>
                    )}
                </div>

                <div className='c-promo-recommendation-content'>
                    <p className='product-name u-text-bold'>{product?.name ?? ''}</p>
                    <p className='promo-name u-text-black'>{promotion.name ?? ''}</p>
                </div>

                <div className='c-promo-recommendation-controls'>
                    <button
                        style={promotion.color ? { background: promotion.color } : {}}
                        className='c-promo-recommendation-button u-text-bold'
                        onClick={onPromoClick}>
                        {t('discountcode.add.cta')}
                    </button>
                </div>
            </div>
        )
    }

    return null
}

export default PromoRecommendation
