import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: navigator.language || navigator.userLanguage,
        fallbackLng: 'en',
        supportedLngs: ['nl', 'fr', 'en', 'es', 'de', 'it', 'sl'],
        whitelist: ['nl', 'fr', 'en', 'es', 'de', 'it', 'sl'],
        nonExplicitWhitelist: true,
        load: 'languageOnly',
        cleanCode: true,
        debug: false,
        nonExplicitSupportedLngs: true,
        keySeparator: false,
        ns: ['translation', 'overrides'],
        defaultNS: 'overrides',
        fallbackNS: 'translation',
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

i18n.on('languageChanged', (chosenLanguage) => {
    document.documentElement.setAttribute('lang', chosenLanguage)
})

export default i18n
