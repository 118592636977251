import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik'
import { motion } from 'framer-motion'
import * as Yup from 'yup'

import { staggerFadeInUpChild, staggerFadeInUpContainer } from '../../constants/Animations.ts'

import RadioButton from './elements/RadioButton'

function PromoSelectProductForm({ color, promoProducts, initialPromoProductValue, onSubmit = () => undefined }) {
    const { t } = useTranslation()

    const onHandleSubmit = useCallback(
        (values) => {
            onSubmit(parseInt(values.chosenPromoProduct, 10))
        },
        [onSubmit]
    )

    return (
        <Formik
            initialValues={{
                chosenPromoProduct: initialPromoProductValue
                    ? String(initialPromoProductValue)
                    : String(promoProducts[0].id),
            }}
            validationSchema={Yup.object().shape({
                chosenPromoProduct: Yup.string().required(t('validation.email.required')),
            })}
            onSubmit={(values) => {
                onHandleSubmit(values)
            }}>
            {({ values, isSubmitting, setFieldValue }) => (
                <Form>
                    <motion.div
                        variants={staggerFadeInUpContainer}
                        initial='hidden'
                        animate='show'>
                        {typeof initialPromoProductValue === 'undefined' && promoProducts.length > 1 ? (
                            <motion.div
                                className='c-promo-products'
                                variants={staggerFadeInUpChild}>
                                {promoProducts.map((promoProduct) => (
                                    <Field
                                        key={String(promoProduct.id)}
                                        component={RadioButton}
                                        id={String(promoProduct.id)}
                                        value={values.chosenPromoProduct}
                                        name={String(promoProduct.id)}
                                        type='radio'
                                        overrideLabel={promoProduct.name}
                                        disabled={isSubmitting}
                                        onChange={() => {
                                            setFieldValue('chosenPromoProduct', String(promoProduct.id))
                                        }}
                                        className='c-radio--addition'
                                    />
                                ))}
                            </motion.div>
                        ) : null}

                        <motion.div variants={staggerFadeInUpChild}>
                            <input
                                style={color && { background: color }}
                                className='c-button c-button-dark'
                                type='submit'
                                disabled={isSubmitting}
                                value={
                                    isSubmitting
                                        ? t('button.form.disabled.cta')
                                        : t('promotions.add_to_basket.cta', 'Add to basket')
                                }
                            />
                        </motion.div>
                    </motion.div>
                </Form>
            )}
        </Formik>
    )
}

export default PromoSelectProductForm
