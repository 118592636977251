import { createContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import useEventQuery from '../hooks/useEventQuery'
import RoutePath from '../structures/Enums/RoutePath.enum.ts'

export const EventContext = createContext({
    isLoading: true,
    isFetching: undefined,
    setIsLoading: () => undefined,
    event: undefined,
    // eslint-disable-next-line no-unused-vars
    setEvent: (event) => undefined,
    // eslint-disable-next-line no-unused-vars
    fetchEventById: (id) => undefined,
    error: undefined,
})

function EventContextProvider({ children }) {
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const [id, setId] = useState(undefined)

    const { isLoading, error, isError, event, isFetching } = useEventQuery(id)

    const fetchEventById = (id) => {
        queryClient.invalidateQueries({ queryKey: ['event'] })
        setId(id)
    }

    const setEvent = ({ id }) => fetchEventById(id)

    useEffect(() => {
        if (error?.status === 404) navigate(RoutePath.HOMEPAGE, { replace: true })
    }, [error])

    const providerData = useMemo(
        () => ({ isLoading, isFetching, fetchEventById, event, setEvent, isError, error }),
        [isLoading, event, isError, error]
    )

    return <EventContext.Provider value={providerData}>{children}</EventContext.Provider>
}

export default EventContextProvider
