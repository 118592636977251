import { useContext, useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { ThemeContext } from '../context/ThemeContextProvider'
import BillySDK from '../sdk/sdk'
import RoutePath from '../structures/Enums/RoutePath.enum.ts'
import { isNotNullOrUndefined } from '../structures/Guards/guards.utils.ts'

function useEventGroupQuery(eventGroupId) {
    const { setTheme } = useContext(ThemeContext)
    const eventGroupMatchPath = useMatch(RoutePath.EVENT_GROUP)
    const orderGroupMatchPath = useMatch(RoutePath.ORDER_GROUP)

    const {
        isLoading,
        isError,
        data: eventGroup,
    } = useQuery({
        retry: 2,
        refetchOnWindowFocus: true,
        staleTime: 60 * 1000,
        refetchInterval: 60 * 3 * 1000,
        queryKey: ['eventGroup', { eventGroupId }],
        enabled: isNotNullOrUndefined(eventGroupId),
        queryFn: () => BillySDK.getEventGroupById(eventGroupId, null),
    })

    useEffect(() => {
        if (isNotNullOrUndefined(eventGroup?.data?.master_venue)) {
            if (isNotNullOrUndefined(orderGroupMatchPath) || isNotNullOrUndefined(eventGroupMatchPath)) {
                setTheme({
                    ...eventGroup.data.master_venue.theming,
                    ...{
                        eventGroupPath: `/eventgroups/${eventGroup.data.id}`,
                    },
                })
            }
        }
    }, [eventGroup, orderGroupMatchPath, eventGroupMatchPath])

    return {
        isLoading,
        isError,
        eventGroup: eventGroup?.data,
    }
}

export default useEventGroupQuery
