import { useContext, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

import { ThemeContext } from '../context/ThemeContextProvider'
import BillySDK from '../sdk/sdk'

import useTranslationOverride from './useTranslationOverride'

function useEventQuery(eventId, params = null) {
    const { setTheme } = useContext(ThemeContext)

    // force tranlsation override
    useTranslationOverride(eventId)

    const {
        isLoading,
        error,
        isFetching,
        isError,
        data: event,
    } = useQuery({
        retry: 2,
        staleTime: 60 * 1000,
        refetchOnWindowFocus: true,
        refetchInterval: 60 * 3 * 1000,
        queryKey: ['event', { eventId }],
        enabled: !!eventId,
        queryFn: () => BillySDK.getEventById(eventId, params),
    })

    useEffect(() => {
        if (event?.data) {
            setTheme({
                ...(event.data.theming ?? {}),
                ...{
                    allowsGuestUsers: event.data.allows_guest_users || false,
                    placePath: event.data.venue?.place?.id ? `/places/${event.data.venue.place.id}` : undefined,
                    venuePath: event.data.venue?.id ? `/venues/${event.data.venue.id}` : undefined,
                    venueScreenDisabled: event.data.venue?.has_multiple_events === false,
                    customDomain: event.data.venue?.custom_domain,
                    customTags: event.data.venue?.custom_tags,
                },
            })
        }
    }, [event])

    return {
        isLoading,
        error,
        isFetching,
        isError,
        event: event?.data,
    }
}

export default useEventQuery
