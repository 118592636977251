import { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import QrScanhelp from '../../../components/QrScanHelp/QrScanhelp'
import { popIn } from '../../../constants/Animations.ts'
import { KioskContext } from '../../../context/KioskContextProvider'
import { closeModal, openModal } from '../../../redux/actions/actionBuilders'
import BillySDK from '../../../sdk/sdk'
import OrderableType from '../../../structures/Enums/OrderableType.enum.ts'
import { ERROR_MODAL } from '../modalTypes'

import './modal.scss'

function PayByQrCodeModal({ data }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { setIsIdleTimerEnabled } = useContext(KioskContext)

    const onCancelPayment = useCallback(() => {
        if (data.order.type === OrderableType.ORDER) {
            BillySDK.cancelPayment(data.order.id, null)
                .then(() => {
                    dispatch(closeModal())
                })
                .catch(() => {
                    dispatch(
                        openModal({
                            type: ERROR_MODAL,
                        })
                    )
                })
        } else {
            BillySDK.cancelGroupPayment(data.order.id, null)
                .then(() => {
                    dispatch(closeModal())
                })
                .catch(() => {
                    dispatch(
                        openModal({
                            type: ERROR_MODAL,
                        })
                    )
                })
        }
    }, [data])

    useEffect(() => {
        setIsIdleTimerEnabled(false)
        return () => setIsIdleTimerEnabled(true)
    }, [])

    return (
        <>
            <motion.div
                className='c-modal--item'
                variants={popIn}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <div className='c-modal--item-content c-modal--pay-with-qr'>
                    <img
                        className='qr-code'
                        src={data.qrUrl}
                    />
                    <QrScanhelp
                        label={t('kiosk.scanhelp')}
                        className='qr-help'
                    />
                </div>
            </motion.div>
            <button
                onClick={onCancelPayment}
                className='qr-cancel-payment'>
                {t('button.change_payment_method.cta')}
            </button>
        </>
    )
}

export default PayByQrCodeModal
