import { FC, ReactNode } from 'react'
import { motion } from 'framer-motion'

import './kioskinstructionspanel.scss'

interface IKioskInstructionsPanelProperties {
    children?: ReactNode | null
}

const KioskInstructionsPanel: FC<IKioskInstructionsPanelProperties> = ({ children = null }) => (
    <motion.div
        initial={{ transform: 'translateY(10vh)' }}
        animate={{ transform: 'translateY(0vh)' }}
        exit={{ transform: 'translateY(50vh)' }}
        className='c-kiosk-intro--trigger'>
        <div>{children}</div>
    </motion.div>
)

export default KioskInstructionsPanel
