import { SET_CASHLESS_CARD } from '../actions/cashlessCardActions'

export default function cashlessCardReducer(state = {}, { type, payload }) {
    // NOSONAR
    switch (type) {
        case SET_CASHLESS_CARD:
            state = {
                ...state,
                [payload.cardType]: {
                    cardNumber: String(payload.cardNumber),
                },
            }

            return state

        default:
            return state
    }
}
