import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import Additions from '../../../components/03_organisms/Additions/Additions.tsx'
import ScrollPane from '../../../components/common/ScrollPane'
import { useScreenHeight } from '../../../components/common/withScreenHeight'
import { slideInBottom } from '../../../constants/Animations.ts'
import useValidateAdditions from '../../../hooks/useValidateAdditions.ts'
import { appendAdditionsToProduct, closeModal } from '../../../redux/actions/actionBuilders'
import { isNotNullOrUndefined } from '../../../structures/Guards/guards.utils.ts'

import ProductHeader from './ProductHeader'

import './modal.scss'

function UpsellAdditionsModal({ data }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const screenHeight = useScreenHeight()

    const { validateAdditions, totalAdditionsPrice, selectedAdditions, isValid } = useValidateAdditions(
        data.product?.additions
    )

    const onConfirm = useCallback(() => {
        const { product, event_id: eventId } = data
        dispatch(
            appendAdditionsToProduct({
                ...product,
                event_id: eventId,
                additions: selectedAdditions,
                has_additions: !!Object.keys(selectedAdditions).length,
                totalAdditionsPrice,
            })
        )

        data.onConfirm?.()
        dispatch(closeModal())
    }, [dispatch, selectedAdditions, totalAdditionsPrice])

    const onUpdateAdditions = useCallback(
        (values) => {
            validateAdditions(values)
        },
        [data.product.additions]
    )

    return (
        <div style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}>
            <motion.div
                className='c-modal--item c-modal--addtobasket-item'
                variants={slideInBottom}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <div className=''>
                    <ScrollPane
                        style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}
                        className='c-modal--item-content'>
                        <ProductHeader
                            name={data.product?.name ?? 'Product'}
                            // show description with header if no additions
                            description={
                                isNotNullOrUndefined(data.product?.additions) && data.product.additions.length === 0
                                    ? data.product.description
                                    : null
                            }
                            image={
                                isNotNullOrUndefined(data.product?.images) && data.product.images.medium
                                    ? data.product.images.medium
                                    : null
                            }
                            imageSize={data.product.image_size ?? 'cover'}
                            allergens={data.product.allergens}
                            hasAdditions={data.product.additions?.length > 0}
                        />
                        {data.is_orderable === false ? (
                            <div className='c-modal--item-not-orderable'>
                                <p>{t('product.not_orderable.description')}</p>
                            </div>
                        ) : null}

                        {
                            // show description beneath header if product has additions
                            isNotNullOrUndefined(data.product?.description) &&
                            isNotNullOrUndefined(data.product?.additions) &&
                            data.product.additions.length > 0 ? (
                                <div className='c-modal--item-description'>
                                    <p>{data.product.description}</p>
                                </div>
                            ) : null
                        }

                        {isNotNullOrUndefined(data.product?.additions) && data.product.additions.length > 0 ? (
                            <Additions
                                isOrderable
                                additions={data.product.additions}
                                onAdditionsChange={onUpdateAdditions}
                            />
                        ) : null}
                    </ScrollPane>

                    <motion.div className='c-modal--item-close c-modal--addtobasket-actions'>
                        <button
                            className='c-basket--order'
                            onClick={onConfirm}
                            disabled={!isValid}>
                            {t('button.confirm.cta')}
                        </button>
                    </motion.div>
                </div>
            </motion.div>
        </div>
    )
}

export default UpsellAdditionsModal
