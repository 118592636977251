import i18n from '../../i18next-config'
import IBrand from '../Interfaces/IBrand'

import BrandType from './BrandType.enum'

export const BRAND_BILLY: IBrand = {
    id: 0,
    brandName: 'Billy',
    identifier: BrandType.BILLY,
    languages: i18n.options.supportedLngs,
    styles: null,
    theme: {
        logo: {
            header: '/brand/billy/billy_big.svg',
            small: '/brand/billy/billy_small.svg',
            big: '/brand/billy/billy_big.svg',
        },
        color: {
            primary: '#EA5056',
            background: '#f2f2f2',
        },
    },
}

// bees-staging.billy.gent
export const BRAND_BEES: IBrand = {
    id: 1,
    brandName: 'Bees',
    identifier: BrandType.BEES,
    languages: ['nl', 'fr', 'en'],
    styles: null,
    theme: {
        logo: {
            header: '/brand/bees/bees_small.svg',
            small: '/brand/bees/bees_small.svg',
            big: '/brand/bees/bees_big.svg',
        },
        color: {
            primary: '#000000',
            background: '#f2f2f2',
        },
    },
}

// qbh-staging.billy.gent
export const BRAND_QBH: IBrand = {
    id: 2,
    brandName: 'Q-Beach House',
    identifier: BrandType.QBH,
    languages: ['nl', 'fr', 'en'],
    styles: 'qbh',
    theme: {
        logo: {
            header: '/brand/qbh/qbh_big.svg',
            small: '/brand/qbh/qbh_small.svg',
            big: '/brand/qbh/qbh_big.svg',
        },
        color: {
            primary: '#A6CE99',
            background: '#DFECD9',
        },
    },
}

// tada-staging.billy.gent
export const BRAND_TADA: IBrand = {
    id: 3,
    brandName: 'TaDa',
    identifier: BrandType.TADA,
    languages: ['nl', 'fr', 'en'],
    styles: null,
    theme: {
        logo: {
            header: '/brand/tada/tada_small.svg',
            small: '/brand/tada/tada_small.svg',
            big: '/brand/tada/tada_big.svg',
        },
        color: {
            primary: '#270a45',
            background: '#f2f2f2',
        },
    },
}

// tada-staging.billy.gent
export const BRAND_CROUSTICO: IBrand = {
    id: 4,
    brandName: 'Croustico',
    identifier: BrandType.CROUSTICO,
    languages: ['nl', 'fr', 'en'],
    styles: 'croustico',
    theme: {
        logo: {
            header: '/brand/croustico/croustico_big.svg',
            small: '/brand/croustico/croustico_small.svg',
            big: '/brand/croustico/croustico_big.svg',
        },
        color: {
            primary: '#E18C2D',
            background: '#FFFAF5',
        },
    },
}

export const getBrand = () => {
    let brand = BRAND_BILLY
    if (window.location.hostname === import.meta.env.REACT_APP_BRAND_DOMAIN_BEES) brand = BRAND_BEES
    if (window.location.hostname === import.meta.env.REACT_APP_BRAND_DOMAIN_QBH) brand = BRAND_QBH
    if (window.location.hostname === import.meta.env.REACT_APP_BRAND_DOMAIN_TADA) brand = BRAND_TADA
    if (window.location.hostname === import.meta.env.REACT_APP_BRAND_DOMAIN_CROUSTICO) brand = BRAND_CROUSTICO
    return brand
}
