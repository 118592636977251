import { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import AutoHeight from 'embla-carousel-auto-height'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'

import useIsMounted from '../../hooks/useIsMounted'
import { isNotNull } from '../../structures/Guards/guards.utils.ts'

import Slide from './Slide'

import './carousel.scss'

function Carousel({ children, autoplayDelay, autoHeight = false, config = {} }) {
    const isMounted = useIsMounted()

    const [selectedIndex, setSelectedIndex] = useState(0)
    const [scrollSnaps, setScrollSnaps] = useState([])

    const defaultConfig = {
        dots: true,
        skipSnaps: false,
    }

    config = { ...defaultConfig, ...config }

    const autoPlayPlugin = useRef(
        autoplayDelay && !Number.isNaN(autoplayDelay)
            ? Autoplay({
                  delay: autoplayDelay,
                  stopOnInteraction: false,
                  stopOnMouseEnter: true,
                  rootNode: (emblaRoot) => emblaRoot.parentElement,
              })
            : null
    )
    const autoHeightPlugin = useRef(autoHeight ? AutoHeight({ destroyHeight: 'auto' }) : null)

    const pluginArray = []
    if (isNotNull(autoPlayPlugin.current)) pluginArray.push(autoPlayPlugin.current)
    if (isNotNull(autoHeightPlugin.current)) pluginArray.push(autoHeightPlugin.current)

    const [emblaRef, emblaApi] = useEmblaCarousel(config, pluginArray)

    const onSelect = useCallback(() => {
        if (emblaApi?.selectedScrollSnap) setSelectedIndex(emblaApi.selectedScrollSnap())
    }, [emblaApi])

    const onInit = useCallback(() => {
        if (emblaApi?.scrollSnapList) setScrollSnaps(emblaApi?.scrollSnapList())
    }, [emblaApi])

    useEffect(() => {
        if (emblaApi && isMounted()) {
            onInit(emblaApi)
            onSelect(emblaApi)
            emblaApi.on('reInit', onInit)
            emblaApi.on('reInit', onSelect)
            emblaApi.on('select', onSelect)
        }

        return () => {
            emblaApi?.destroy()
        }
    }, [emblaApi, onInit, onSelect, isMounted])

    return (
        <div className='carousel'>
            <div
                className='carousel__viewport'
                ref={emblaRef}>
                <div className='carousel__container'>{children}</div>
            </div>
            {config.dots === true && scrollSnaps.length > 1 ? (
                <div className='slide-progress'>
                    {scrollSnaps.map((item, index) => (
                        <span
                            key={index}
                            className={classNames('slide-progress--dot', { active: selectedIndex === index })}
                        />
                    ))}
                </div>
            ) : (
                <div className='slide-progress empty' />
            )}
        </div>
    )
}

Carousel.Slide = Slide

export default Carousel
