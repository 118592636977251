import { useCallback, useContext, useState } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import ScrollPane from '../../../components/common/ScrollPane'
import { useScreenHeight } from '../../../components/common/withScreenHeight'
import PromoRegisterForm from '../../../components/forms/PromoRegisterForm'
import PromoSelectProductForm from '../../../components/forms/PromoSelectProductForm'
import PromoValidationForm from '../../../components/forms/PromoValidationForm'
import PoweredBy from '../../../components/PoweredBy/PoweredBy'
import { slideInBottom } from '../../../constants/Animations.ts'
import { UserContext } from '../../../context/UserAndTokenContextProvider'
import useTimeout from '../../../hooks/useTimeout'
import { closeModal } from '../../../redux/actions/actionBuilders'
import { BRAND_TADA } from '../../../structures/Enums/Brands.enum.ts'

import './modal.scss'

function PromoModal({ data }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { user } = useContext(UserContext)
    const screenHeight = useScreenHeight()

    const [exploding, setExploding] = useState(false)
    const [otpGenerated, setOtpGenerated] = useState(false)

    const [chosenPromoProductId, setChosenPromoProductId] = useState()

    const explosionProps = {
        force: 1,
        width: 600,
        duration: 4000,
        particleCount: 100,
    }

    const onClose = useCallback(() => {
        dispatch(closeModal())
    }, [])

    const onSuccessfullGenerate = useCallback((chosenProductId) => {
        setChosenPromoProductId(
            data.promo.products.find((p) => p.id === chosenProductId)?.id ?? data.promo.products[0].id
        )
        setOtpGenerated(true)
    }, [])

    const onSuccessfullVerification = useCallback(() => {
        setExploding(true)
    }, [])

    useTimeout(() => data?.onSuccess?.(chosenPromoProductId), exploding ? 1800 : null)

    const renderPromoForm = useCallback(() => {
        if (user?.phoneVerified === null || typeof user?.phoneVerified === 'undefined') {
            if (otpGenerated) {
                return (
                    <PromoValidationForm
                        onSuccessfullVerify={onSuccessfullVerification}
                        color={data.promo.color}
                    />
                )
            }
            return (
                <PromoRegisterForm
                    onSuccessfullGenerate={onSuccessfullGenerate}
                    color={data.promo.color}
                    promoProducts={data.promo.products}
                    initialPromoProductValue={data.defaultPromoProductId}
                />
            )
        }

        return (
            <PromoSelectProductForm
                color={data.promo.color}
                promoProducts={data.promo.products}
                initialPromoProductValue={data.defaultPromoProductId}
                onSubmit={(product) => {
                    setChosenPromoProductId(product)
                    setExploding(true)
                }}
            />
        )
    }, [data, otpGenerated, exploding])

    if (data?.promo) {
        return (
            <div style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}>
                <motion.div
                    className='c-modal--item c-modal--promo'
                    variants={slideInBottom}
                    initial='hidden'
                    animate='visible'
                    exit='exit'>
                    <div className='c-fake-modal-backdrop'>
                        <div className='c-close-modal-header'>
                            <button
                                aria-label={t('general.button.close.label')}
                                onClick={onClose}
                                className='c-button-close-modal-small'>
                                <FaTimes />
                            </button>
                        </div>

                        <ScrollPane
                            style={{
                                maxHeight: `${parseInt(screenHeight * 0.9, 10)}px`,
                                overflow: exploding ? 'hidden' : 'auto',
                            }}
                            className='c-modal--item-content'>
                            <div className='c-modal-promo-wrapper'>
                                <div
                                    className='c-modal-promo-image'
                                    style={{ background: data.promo.color }}>
                                    {data.promo.coverImage ? <img src={data.promo.coverImage} /> : null}
                                </div>
                                <div
                                    className={classNames('c-modal-promo-content', {
                                        withProductSelector:
                                            data.promo.products.length > 1 &&
                                            typeof data.defaultPromoProductId === 'undefined',
                                    })}>
                                    <motion.div
                                        initial={{ scale: 0.95, y: 50 }}
                                        animate={{ scale: 1, y: 0, transition: { delay: 0.15 } }}
                                        className='c-modal-promo-inner'>
                                        <div
                                            className='c-modal-promo-head'
                                            style={{ paddingBottom: data.promo.description ? '10px' : '30px' }}>
                                            <p
                                                className='c-modal-promo-promoname'
                                                style={
                                                    data.promo.color && {
                                                        color: `#${String(data.promo.color).replace('#', '')}`,
                                                    }
                                                }>
                                                {data.promo.name}
                                            </p>

                                            <PoweredBy
                                                brandId={BRAND_TADA.identifier}
                                                isStaticPositioned
                                            />
                                        </div>
                                        <div className='c-modal-promo-forms'>
                                            <div className='c-modal-promo-form'>{renderPromoForm()}</div>
                                            {exploding ? (
                                                <div className='success-explosion'>
                                                    <ConfettiExplosion {...explosionProps} />
                                                </div>
                                            ) : null}
                                            <button
                                                disabled={exploding}
                                                onClick={onClose}
                                                className='c-modal-promo-deny'>
                                                {t('general.button.close.label')}
                                            </button>
                                        </div>
                                    </motion.div>
                                    <p className='c-modal-promo-legal'>{t('legal.alcohol.plus18')}</p>
                                </div>
                            </div>
                        </ScrollPane>
                    </div>
                </motion.div>
            </div>
        )
    }

    return null
}

export default PromoModal
