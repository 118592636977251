import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import ScrollPane from '../../../components/common/ScrollPane'
import { useScreenHeight } from '../../../components/common/withScreenHeight'
import QuantitySelector from '../../../components/QuantitySelector/QuantitySelector.tsx'
import { slideInBottom } from '../../../constants/Animations.ts'
import { closeModal, removeProduct, updateBasket } from '../../../redux/actions/actionBuilders'

import ProductHeader from './ProductHeader'

import './modal.scss'

function QuantitySelectorModal({ data }) {
    const [amount, setAmount] = useState(data.amount)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const screenHeight = useScreenHeight()

    const onConfirm = () => {
        if (amount === 0) {
            dispatch(removeProduct(data.product))
            data.onConfirm?.()
        } else {
            dispatch(
                updateBasket({
                    ...data.product,
                    amount,
                })
            )

            data.onConfirm?.()
        }

        dispatch(closeModal())
    }

    const onUpdateAmount = (adjuster) => {
        setAmount(Math.max(amount + adjuster, 0))
    }

    const onClose = useCallback(() => {
        dispatch(closeModal())
    }, [])

    return (
        <div style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}>
            <motion.div
                className='c-modal--item c-modal--addtobasket-item'
                variants={slideInBottom}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <div className='c-fake-modal-backdrop'>
                    <div className='c-close-modal-header'>
                        <button
                            aria-label={t('general.button.close.label')}
                            onClick={onClose}
                            className='c-button-close-modal-small'>
                            <FaTimes />
                        </button>
                    </div>

                    <ScrollPane
                        style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}
                        className='c-modal--item-content'>
                        <ProductHeader
                            name={data.product.name}
                            image={
                                data.product.images && data.product.images.medium ? data.product.images.medium : null
                            }
                            imageSize={data.product.image_size ? data.product.image_size : 'cover'}
                            allergens={data.product.allergens}
                            hasAdditions={false}
                        />
                    </ScrollPane>

                    <motion.div className='c-modal--item-close c-modal--addtobasket-actions'>
                        {data.is_orderable === false ? (
                            <button
                                className='c-basket--order'
                                onClick={onClose}>
                                {t('button.order.status.back_to_event.cta')}
                            </button>
                        ) : (
                            <>
                                <div className='c-quantityselector-wrapper'>
                                    <QuantitySelector
                                        type='small'
                                        amount={amount}
                                        range={{
                                            min: 0,
                                            max: data.amount + data.maxOrderableAmount,
                                        }}
                                        onQuantityChange={onUpdateAmount}
                                    />
                                    {data.amount + data.maxOrderableAmount <= 20 ? (
                                        <p className='c-quantityselector-maxorderablelabel'>
                                            {t('product.maximum_allowed_in_basket.label', {
                                                amount: data.amount + data.maxOrderableAmount,
                                            })}
                                        </p>
                                    ) : null}
                                </div>

                                <button
                                    className='c-basket--order'
                                    onClick={onConfirm}>
                                    {t('button.confirm.cta')}
                                </button>
                            </>
                        )}
                    </motion.div>
                </div>
            </motion.div>
        </div>
    )
}

export default QuantitySelectorModal
