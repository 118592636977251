import './creditstutorial.scss'

function CreditsTutorialSlide({ title, body, imagePath }) {
    return (
        <div className='c-credits-tutorial-slide'>
            <div className='c-credits-tutorial-slide--image'>
                {imagePath && (
                    <img
                        src={imagePath}
                        alt='credits'
                    />
                )}
            </div>

            <div className='c-credits-tutorial-content'>
                {title && <h3 className='u-text-bold'>{title}</h3>}
                {body && <p>{body}</p>}
            </div>
        </div>
    )
}

export default CreditsTutorialSlide
