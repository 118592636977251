import { FC, useCallback, useEffect, useState } from 'react'
import { IAdditionValue } from 'src/hooks/useValidateAdditions'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IAddition from 'src/structures/Interfaces/IAddition'

import Addition from '../../02_molecules/Addition/Addition'
import ComboProduct from '../ComboProduct/ComboProduct'

import './additions.scss'

interface IAdditionsProperties {
    additions: Array<IAddition>
    isOrderable: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onAdditionsChange: (additionsState: { isValid: boolean; values: Array<IAdditionValue> }) => void
}

const Additions: FC<IAdditionsProperties> = ({ additions, isOrderable, onAdditionsChange }) => {
    const [mergedAdditionsValues, setMergedAdditionsValues] = useState<Record<string, IAdditionValue>>({})

    const handleAdditionValueChange = useCallback((values: IAdditionValue) => {
        setMergedAdditionsValues((previousState) => ({ ...previousState, [values.id.toString()]: values }))
    }, [])

    useEffect(() => {
        if (Object.keys(mergedAdditionsValues).length > 0) {
            const additionsArray = Object.values(mergedAdditionsValues)
            const allAdditionGroupsValid = additionsArray.every((v) => v.isValid)
            onAdditionsChange({ isValid: allAdditionGroupsValid, values: additionsArray })
        }
    }, [mergedAdditionsValues, onAdditionsChange])

    if (additions?.length > 0) {
        return (
            <div className='c-product--additions'>
                {additions.map((addition) => {
                    if (isNotNullOrUndefined(addition.sub_products) && addition.sub_products.length > 0) {
                        return (
                            <ComboProduct
                                key={addition.id}
                                addition={addition}
                                isOrderable={isOrderable}
                                onValues={handleAdditionValueChange}
                            />
                        )
                    }
                    return (
                        <Addition
                            key={addition.id}
                            addition={addition}
                            isOrderable={isOrderable}
                            onValues={handleAdditionValueChange}
                        />
                    )
                })}
            </div>
        )
    }

    return null
}

export default Additions
