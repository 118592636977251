import classNames from 'classnames'

import { sanitizeHtml } from '../../../constants/helpers'

function RadioButton({
    field: { name, value, onChange, onBlur },
    id,
    label,
    overrideLabel,
    disabled = false,
    className,
    ...props
}) {
    return (
        <div className={classNames('c-radio--default', className)}>
            <input
                name={name}
                id={id}
                type='radio'
                value={id}
                checked={id === value}
                onChange={onChange}
                disabled={disabled}
                onBlur={onBlur}
                className={classNames('radio')}
                {...props}
            />
            {overrideLabel ? (
                <label
                    htmlFor={id}
                    style={{ paddingRight: 24 }}>
                    {overrideLabel}
                </label>
            ) : (
                <label
                    htmlFor={id}
                    style={{ paddingRight: 24 }}
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(label) }}
                />
            )}
        </div>
    )
}

export default RadioButton
