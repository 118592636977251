import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { captureException, setTag, withScope } from '@sentry/react'

import { EventContext } from '../context/EventContextProvider'
import { EventGroupContext } from '../context/EventGroupContextProvider'
import { UserContext } from '../context/UserAndTokenContextProvider'
import { removeAllLoyalty, updateTokens } from '../redux/actions/actionBuilders'
import BillySDK from '../sdk/sdk'
import UserType from '../structures/Enums/UserType.ts'
import { isNotNullOrUndefined } from '../structures/Guards/guards.utils.ts'

function useKioskUser(kioskMode) {
    const dispatch = useDispatch()
    const { event } = useContext(EventContext)
    const { eventGroup } = useContext(EventGroupContext)
    const { user, updateUser } = useContext(UserContext)
    const storedAccessToken = useSelector((state) => state.tokens?.access_token)
    const { device } = useSelector((state) => state.kiosk)
    const [loggingIn, setLoggingIn] = useState(false)

    const updateAccessTokens = useCallback(
        (token) => {
            dispatch(updateTokens({ access_token: token }))
        },
        [dispatch]
    )

    const catchAuthException = useCallback(
        (errorType, error) => {
            dispatch(removeAllLoyalty())
            updateAccessTokens(undefined)
            updateUser(undefined)

            withScope(() => {
                setTag('Kiosk', event?.name ?? 'unknown')
                setTag('EventID', event?.id ?? 'unknown')
                setTag('EventGroup', eventGroup?.name ?? 'unknown')
                setTag('EventGroupID', eventGroup?.id ?? 'unknown')
                setTag('KioskMode', true)
                setTag('DeviceId', device?.id ?? 'unknown')
                captureException(new Error(`Kiosk ${errorType} Error: ${error.message}`))
            })
        },
        [dispatch, event, eventGroup, device, updateAccessTokens, updateUser]
    )

    const kioskLogin = useCallback(() => {
        if (loggingIn) return
        setLoggingIn(true)
        BillySDK.kioskLogin()
            .then((response) => updateAccessTokens(response.token))
            .catch((error) => catchAuthException('guestLogin', error))
            .finally(() => setLoggingIn(false))
    }, [loggingIn, updateAccessTokens, catchAuthException])

    const kioskStaffLogin = useCallback(() => {
        updateAccessTokens(device?.kioskUserAccessToken)
    }, [device, updateAccessTokens])

    const resetKioskUser = useCallback(() => {
        if (user?.type === UserType.USER && device?.kioskUserAccessToken) {
            dispatch(removeAllLoyalty())
            return
        }

        BillySDK.logout()
            .then(() => {
                dispatch(removeAllLoyalty())
                updateAccessTokens(undefined)
                updateUser(undefined)
            })
            .catch((error) => catchAuthException('logOut', error))
    }, [user, device, dispatch, updateAccessTokens, updateUser, catchAuthException])

    useEffect(() => {
        if (isNotNullOrUndefined(device) && storedAccessToken === undefined && kioskMode === true) {
            if (isNotNullOrUndefined(device.kioskUserAccessToken)) {
                kioskStaffLogin()
            } else {
                kioskLogin()
            }
        }
    }, [device, storedAccessToken, kioskMode, kioskLogin, kioskStaffLogin])

    return {
        user,
        resetKioskUser,
    }
}

export default useKioskUser
