import { useCallback } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import classNames from 'classnames'

import './rating.scss'

interface IRatingButtonProperties {
    active?: boolean
    value?: number
    mood?: 'negative' | 'neutral' | 'positive'
    imageId?: string
    disabled?: boolean
    onClick?: (value: number) => void
}

const RatingButton = ({
    active = false,
    value = 1,
    mood = 'positive',
    imageId = '1',
    disabled = false,
    onClick = () => {},
}: IRatingButtonProperties) => {
    const handleClick = useCallback(() => {
        if (!disabled) {
            onClick?.(value)
        }
    }, [disabled, value, onClick])

    const explosionProperties = {
        force: value / 10,
        width: 10 + value * 100,
        duration: 2500 + value * 100,
        particleCount: value * 10,
    }

    return (
        <button
            className={classNames('c-rating-button', {
                'c-rating-button--active': active,
                'c-rating-button--disabled': disabled,
            })}
            onClick={handleClick}>
            <img
                src={`/rating/rating_${mood}_${imageId}.png`}
                alt='mood'
            />
            {active && (
                <div className='c-confetti'>
                    <ConfettiExplosion {...explosionProperties} />
                </div>
            )}
        </button>
    )
}

export default RatingButton
