import DOMPurify from 'dompurify'

import TimeUnit from '../structures/Enums/TimeUnit.enum.ts'

export const divideByHundred = (value) => {
    const v = parseFloat(value)
    return v / 100
}

export const calculatePercentage = (amount, percentage) => {
    if (typeof amount !== 'number' || typeof percentage !== 'number' || percentage < 0) {
        return amount
    }

    return (amount * percentage) / 100
}

const isUrl = (s) => {
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!-]))?/
    return regexp.test(s)
}

export const isStringBillyPath = (str) => {
    if (!isUrl(str)) return false

    const parser = document.createElement('a')
    parser.href = str

    if (
        parser.hostname !== window.location.hostname &&
        parser.hostname !== 'billy.gent' &&
        parser.hostname !== 'qr.orderbilly.com' &&
        parser.hostname !== 'qr.mybees.be' &&
        parser.hostname !== 'qr.qmusic.be' &&
        parser.hostname !== 'qr.potatoeurope2023.blommerie.com' &&
        parser.hostname !== 'dorst.app' &&
        parser.hostname !== 'mobilemenu.eu' &&
        parser.hostname !== import.meta.env.REACT_APP_BRAND_DOMAIN_QBH &&
        parser.hostname !== import.meta.env.REACT_APP_BRAND_DOMAIN_BEES &&
        parser.hostname !== import.meta.env.REACT_APP_BRAND_DOMAIN_TADA &&
        parser.hostname !== import.meta.env.REACT_APP_BRAND_DOMAIN_CROUSTICO
    ) {
        return false
    }

    return str
}

export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null
}

export const getQuerystringParameterByName = (name) => {
    const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search)
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

export const formatTimestampToRange = (timestamp, startRangeInMinutes = 10, endRangeInMinutes = 10, locale = 'nl') => {
    const [hours, minutes, seconds] = timestamp.split(':').map(Number)

    // Create a date object with the timestamp
    const date = new Date()
    date.setHours(hours, minutes, seconds, 0)

    // Calculate the start and end times by modifying the date object
    const startTime = new Date(date)
    startTime.setMinutes(startTime.getMinutes() - startRangeInMinutes)

    const endTime = new Date(date)
    endTime.setMinutes(endTime.getMinutes() + endRangeInMinutes)

    const formatTime = (time) => NumberFormatter.time(locale).format(time)

    return `${formatTime(startTime)} - ${formatTime(endTime)}`
}

export const isDateExpired = (dateString) => {
    const expiresAt = new Date(dateString)
    const now = new Date()
    return expiresAt < now
}

export const addTimeToCurrentDateTime = (value, unit) => {
    const date = new Date()
    switch (unit) {
        case TimeUnit.SECONDS:
            date.setSeconds(date.getSeconds() + value)
            break
        case TimeUnit.MINUTES:
            date.setMinutes(date.getMinutes() + value)
            break
        case TimeUnit.HOURS:
            date.setHours(date.getHours() + value)
            break
        case TimeUnit.DAYS:
            date.setDate(date.getDate() + value)
            break
        case TimeUnit.YEARS:
            date.setFullYear(date.getFullYear() + value)
            break
        default:
            throw new Error("Invalid time unit. Use 'minutes', 'hours', 'days', or 'years'.")
    }
    const formattedDate = date.toISOString()
    return formattedDate
}

export const NumberFormatter = {
    decimals: (locale = 'nl') =>
        new Intl.NumberFormat(locale, {
            style: 'decimal',
            minimumFractionDigits: 2,
            useGrouping: false,
        }),
    currency: (currency = 'EUR', locale = 'nl') =>
        new Intl.NumberFormat(locale, {
            style: 'currency',
            currency,
            minimumFractionDigits: 2,
            useGrouping: false,
        }),
    customCurrency: (locale = 'nl') =>
        new Intl.NumberFormat(locale, {
            style: 'decimal',
            minimumFractionDigits: 1,
            maximumFractionDigits: 2,
            useGrouping: false,
        }),
    time: (locale = 'nl') =>
        new Intl.DateTimeFormat(locale, {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        }),
    date: (locale = 'nl') =>
        new Intl.DateTimeFormat(locale, {
            dateStyle: 'long',
        }),
}

export const sanitizeHtml = (input, options = {}) => DOMPurify.sanitize(String(input), { sanitize: true, ...options })
