import { useContext } from 'react'
import classNames from 'classnames'

import Allergen from '../../../components/allergens/Allergen.tsx'
import Allergens from '../../../components/allergens/Allergens'
import { KioskContext } from '../../../context/KioskContextProvider'

import './productheader.scss'

function ProductHeader({ name, description, allergens, image, hasAdditions, imageSize, stickyChildren }) {
    const { kioskMode } = useContext(KioskContext)

    return (
        <>
            <div className={`c-modal--productheader ${image ? 'has-image' : ''} image-${imageSize}`}>
                {image ? (
                    <div className={classNames('c-modal--item-image', { 'c-modal--item-image--kiosk': kioskMode })}>
                        <img src={image} />
                    </div>
                ) : null}
            </div>

            <div
                className={classNames('c-modal--productheader-content', {
                    'c-smaller-modal': !hasAdditions,
                    'has-image': image,
                    hasChildren: stickyChildren,
                })}>
                <p className='c-modal--item-name'>{name}</p>

                {description ? (
                    <div className='c-modal--item-description'>
                        <p>{description}</p>
                    </div>
                ) : null}

                {allergens?.length > 0 ? (
                    <Allergens>
                        {allergens.map((allergen) => (
                            <Allergen
                                key={allergen}
                                id={allergen}
                            />
                        ))}
                    </Allergens>
                ) : null}

                {stickyChildren}

                <div className='c-modal--productheader-border' />
            </div>
        </>
    )
}

export default ProductHeader
