import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import KioskInstructionsIcon from '../KioskInstructionsIcon/KioskInstructionsIcon'
import KioskInstructionsPanel from '../KioskInstructionsPanel/KioskInstructionsPanel'

import ScanIcon from './icons/scanIcon.svg'
import TouchIcon from './icons/touchIcon.svg'

import './kiosktouchtobegin.scss'

interface IKioskTouchToBeginProperties {
    logo?: string | null
    imageUrl?: string | null
    videoUrl?: string | null
}

const KioskTouchToBegin: FC<IKioskTouchToBeginProperties> = ({ logo = null, imageUrl = null, videoUrl = null }) => {
    const { t } = useTranslation()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { kiosk } = useSelector((state) => state) as any
    const isSelfScan = useMemo(() => {
        if (isNotNullOrUndefined(kiosk?.device?.config) && kiosk.device.config.mode === 'self_scan') {
            return true
        }
        return false
    }, [kiosk])

    const imageAnimationVariant = {
        initial: {
            scale: 1.2,
        },
        animate: {
            scale: 1,
        },
        exit: {
            opacity: 0,
            y: '-50vh',
        },
    }

    return (
        <div className='c-kiosk-touch-to-begin-container'>
            {isNotNullOrUndefined(logo) ? (
                <motion.img
                    exit={{
                        y: '-50vh',
                    }}
                    className='c-kiosk-touch-to-begin-logo'
                    src={logo}
                    loading='lazy'
                />
            ) : null}

            <motion.div
                className='c-kiosk-touch-to-begin-image-container'
                variants={imageAnimationVariant}
                initial='initial'
                animate='animate'
                exit='exit'
                transition={{ ease: 'easeInOut', duration: 0.2 }}>
                {isNotNullOrUndefined(videoUrl) ? (
                    <video
                        loop
                        muted
                        autoPlay
                        width='100%'
                        height='100%'
                        poster={isNotNullOrUndefined(imageUrl) ? imageUrl : ''}
                        className='c-kiosk-touch-to-begin-video'>
                        <source
                            src={videoUrl}
                            type='video/mp4'
                        />
                    </video>
                ) : null}

                {isNotNullOrUndefined(imageUrl) && videoUrl === null ? (
                    <img
                        width='100%'
                        height='100%'
                        loading='eager'
                        className='c-kiosk-touch-to-begin-image'
                        src={imageUrl}
                    />
                ) : null}
            </motion.div>

            <KioskInstructionsPanel>
                <KioskInstructionsIcon>
                    <img
                        src={isSelfScan ? ScanIcon : TouchIcon}
                        alt='Touch here'
                    />
                </KioskInstructionsIcon>
                <div>
                    <h1>{t(isSelfScan ? 'kiosk.selfscan.instructions.title' : 'kiosk.intro.title')}</h1>
                </div>
            </KioskInstructionsPanel>
        </div>
    )
}

export default KioskTouchToBegin
