export const DEFAULT_GUEST_NAME = 'Guest' as const

enum UserType {
    GUEST = 'guest',
    GUEST_PHONE = 'guest_phone',
    KIOSK = 'kiosk',
    USER = 'user',
}

export default UserType
