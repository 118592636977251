import { createContext, useCallback, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import useEventGroupQuery from '../hooks/useEventGroupQuery'

export const EventGroupContext = createContext({
    eventGroupId: undefined,
    setEventGroupId: undefined,
    // eslint-disable-next-line no-unused-vars
    fetchEventGroupById: (id) => undefined,
    eventGroup: undefined,
    isEventGroupLoading: undefined,
    isEventGroupError: undefined,
})

function EventGroupContextProvider({ children }) {
    const queryClient = useQueryClient()

    const [eventGroupId, setEventGroupId] = useState(undefined)
    const { isLoading, isError, eventGroup } = useEventGroupQuery(eventGroupId)

    const fetchEventGroupById = useCallback(
        (id) => {
            if (eventGroup?.id !== id) {
                queryClient.invalidateQueries({ queryKey: ['eventGroup'] })
                setEventGroupId(id)
            }
        },
        [eventGroup, queryClient]
    )

    return (
        <EventGroupContext.Provider
            value={{
                eventGroupId,
                setEventGroupId,
                fetchEventGroupById,
                eventGroup,
                isEventGroupLoading: isLoading,
                isEventGroupError: isError,
            }}>
            {children}
        </EventGroupContext.Provider>
    )
}

export default EventGroupContextProvider
