export const slideInBottom = {
    hidden: {
        position: 'relative' as const,
        y: '100vh' as const,
        opacity: 0,
    },
    visible: {
        position: 'relative' as const,
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.05,
            type: 'spring' as const,
            dampening: 1050,
            stiffness: 105,
            mass: 0.2,
        },
    },
    exit: {
        position: 'relative' as const,
        y: '100vh' as const,
        opacity: 0,
    },
}

export const fadeIn = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.01,
            type: 'spring' as const,
            dampening: 1000,
            stiffness: 100,
            mass: 0.2,
        },
    },
    exit: {
        opacity: 0,
    },
}

export const fadeInUp = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.01,
            type: 'spring' as const,
            dampening: 1000,
            stiffness: 100,
            mass: 0.2,
        },
    },
    exit: {
        opacity: 0,
    },
}

export const staggerFadeInUpContainer = {
    hidden: { opacity: 1 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.04,
        },
    },
}

export const staggerFadeInUpChild = {
    hidden: { opacity: 0, y: 30 },
    show: { opacity: 1, y: 0 },
}

export const menuAnimation = {
    hidden: {
        x: '105vw' as const,
    },
    visible: {
        x: 0,
        transition: {
            duration: 0.02,
            type: 'spring' as const,
            dampening: 1000,
            stiffness: 100,
            mass: 0.2,
        },
    },
    exit: {
        x: '105vw' as const,
    },
}

export const basketAnimation = {
    hidden: {
        y: '100vh' as const,
    },
    visible: {
        y: 0,
        transition: {
            delay: 0.2,
            duration: 0.05,
            type: 'spring' as const,
            dampening: 1200,
            stiffness: 120,
            mass: 0.2,
        },
    },
    exit: {
        y: '120vh' as const,
    },
}

export const addToBasketButtonAnimation = {
    hidden: {
        position: 'fixed' as const,
        bottom: '-100%' as const,
    },
    visible: {
        position: 'fixed' as const,
        bottom: 0,
        transition: {
            duration: 0.01,
            type: 'spring' as const,
            dampening: 1900,
            stiffness: 190,
            mass: 0.2,
        },
    },
    exit: {
        position: 'fixed' as const,
        bottom: '-100%' as const,
    },
}

export const popIn = {
    hidden: {
        scale: 0.95,
    },
    visible: {
        scale: 1,
        transition: {
            duration: 0.1,
            type: 'spring' as const,
            dampening: 25,
            stiffness: 250,
            mass: 1,
        },
    },
    exit: {
        scale: 0.95,
    },
}
