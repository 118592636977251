import { Children, cloneElement, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { fadeIn } from '../../constants/Animations.ts'
import { KioskContext } from '../../context/KioskContextProvider'
import { closeModal } from '../../redux/actions/actionBuilders'

import './modalcontainer.scss'

function ModalRoot({ children }) {
    const childrenArray = Children.toArray(children)

    const { kioskMode } = useContext(KioskContext)
    const modalState = useSelector((state) => state.modal)
    const dispatch = useDispatch()

    const [activeModals, setActiveModals] = useState([])

    const onCloseByBackdrop = useCallback(
        (e) => {
            const clickedIndex = e.target.getAttribute('data-index')

            if (clickedIndex !== null && typeof clickedIndex !== 'undefined') {
                if (modalState?.activeModals?.slice(-1)[0]?.data) {
                    modalState?.activeModals?.slice(-1)[0].data?.onCloseByBackdrop?.()
                }

                dispatch(closeModal())
            }

            if (e.target.classList.contains('c-fake-modal-backdrop')) {
                dispatch(closeModal())
            }
        },
        [modalState]
    )

    useEffect(() => {
        const tempArray = []

        modalState?.activeModals?.forEach((activeModal) => {
            const element = childrenArray.find((child) => child.props.type === activeModal.type)
            if (element) {
                const node = cloneElement(element, {
                    type: activeModal.type,
                    data: activeModal.data,
                })

                tempArray.push(node)
            }
        })

        setActiveModals(tempArray)
    }, [modalState])

    return (
        <AnimatePresence>
            {activeModals.length > 0
                ? activeModals.map((modal, i) => (
                      <motion.div
                          key={`${modal.type}-${i}`}
                          className={classNames(
                              'c-modal',
                              modal.props.position === 'bottom' ? 'c-modal--type-bottom' : '',
                              kioskMode === true ? 'kioskmode' : ''
                          )}
                          variants={fadeIn}
                          initial='hidden'
                          animate='visible'
                          exit='exit'
                          data-index={i}
                          onClick={modal.props.canCloseByBackdrop ? onCloseByBackdrop : () => undefined}>
                          <div className='c-modal-main'>
                              <div className='c-modal-content'>{modal}</div>
                          </div>
                      </motion.div>
                  ))
                : null}
        </AnimatePresence>
    )
}

export default ModalRoot
