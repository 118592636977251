import { useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from 'react-redux'

import { INACTIVITY_MODAL } from '../containers/modal/modalTypes'
import { openModal } from '../redux/actions/actionBuilders'

function useIdleMonitor({ disabled = false, timeout = 60000, promptBeforeIdle = 10000, callBack = () => undefined }) {
    const dispatch = useDispatch()

    const onIdle = () => {
        if (disabled === false) callBack?.()
    }

    const onPrompt = () => {
        if (disabled === false) {
            dispatch(
                openModal({
                    type: INACTIVITY_MODAL,
                    data: {
                        timeLeft: promptBeforeIdle,
                        onReset: () => {
                            activate()
                            callBack?.()
                        },
                        onCloseByBackdrop: () => activate(),
                        onStillHere: () => activate(),
                    },
                })
            )
        }
    }

    useEffect(() => {
        if (disabled === false) activate()
    }, [disabled])

    const { activate } = useIdleTimer({
        onIdle,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 1000,
    })
}

export default useIdleMonitor
