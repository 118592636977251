import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { Field, Form, Formik } from 'formik'
import { motion } from 'framer-motion'
import * as Yup from 'yup'

import { staggerFadeInUpChild, staggerFadeInUpContainer } from '../../constants/Animations.ts'
import { ERROR_MODAL } from '../../containers/modal/modalTypes'
import { EventContext } from '../../context/EventContextProvider'
import { UserContext } from '../../context/UserAndTokenContextProvider'
import { openModal, updateTokens } from '../../redux/actions/actionBuilders'
import BillySDK from '../../sdk/sdk'

import RadioButton from './elements/RadioButton'
import PhoneField from './payment/phone/PhoneField'

function PromoRegisterForm({
    color,
    promoProducts,
    initialPromoProductValue,
    onSuccessfullGenerate = () => undefined,
}) {
    const { t } = useTranslation()
    const { user } = useContext(UserContext)
    const { event } = useContext(EventContext)
    const dispatch = useDispatch()

    const guestLogin = useMutation({
        retry: 2,
        refetchOnWindowFocus: false,
        mutationFn: () => BillySDK.guestLogin(),
    })

    const userUpdate = useMutation({
        retry: 2,
        refetchOnWindowFocus: false,
        mutationFn: (userUpdate) => BillySDK.updateUser(userUpdate),
    })

    const generateOtp = useMutation({
        retry: 2,
        refetchOnWindowFocus: false,
        mutationFn: (phone) => BillySDK.promotionsOtpGenerate(phone),
    })

    const onError = useCallback((error, formActions) => {
        dispatch(
            openModal({
                type: ERROR_MODAL,
                data: {
                    message: error?.message,
                },
            })
        )
        formActions.setSubmitting(false)
    }, [])

    const onUserSuccessfullyHandled = useCallback(
        (values, formActions) => {
            generateOtp.mutate(
                { phone_number: values.phone.replace(/\D+/g, '') },
                {
                    onSuccess: () => onSuccessfullGenerate(parseInt(values.chosenPromoProduct, 10)),
                    onError: (error) => onError(error, formActions),
                }
            )
        },
        [generateOtp, onSuccessfullGenerate, onError]
    )

    const onHandleSubmit = useCallback(
        (values, formActions) => {
            if (typeof user === 'undefined') {
                guestLogin.mutate(null, {
                    onSuccess: (response) => {
                        dispatch(
                            updateTokens({
                                access_token: response.token,
                            })
                        )
                        onUserSuccessfullyHandled(values, formActions)
                    },
                    onError: (error) => onError(error, formActions),
                })
            } else {
                userUpdate.mutate(
                    { phone: values.phone.replace(/\D+/g, '') },
                    {
                        onSuccess: () => onUserSuccessfullyHandled(values, formActions),
                        onError: (error) => onError(error, formActions),
                    }
                )
            }
        },
        [onUserSuccessfullyHandled, onError, user]
    )

    return (
        <Formik
            initialValues={{
                phone: user?.phone ? `+${user.phone}` : '',
                chosenPromoProduct: initialPromoProductValue
                    ? String(initialPromoProductValue)
                    : String(promoProducts[0].id),
            }}
            validationSchema={Yup.object().shape({
                phone: Yup.string().required(t('validation.phone.required')),
                chosenPromoProduct: Yup.string().required(t('validation.email.required')),
            })}
            onSubmit={(values, actions) => {
                onHandleSubmit(values, actions)
            }}>
            {({ status, values, isSubmitting, setFieldValue }) => (
                <Form>
                    <motion.div
                        variants={staggerFadeInUpContainer}
                        initial='hidden'
                        animate='show'>
                        {typeof initialPromoProductValue === 'undefined' && promoProducts.length > 1 ? (
                            <motion.div
                                className='c-promo-products'
                                variants={staggerFadeInUpChild}>
                                {promoProducts.map((promoProduct) => (
                                    <Field
                                        key={String(promoProduct.id)}
                                        component={RadioButton}
                                        id={String(promoProduct.id)}
                                        value={values.chosenPromoProduct}
                                        name={String(promoProduct.id)}
                                        type='radio'
                                        overrideLabel={promoProduct.name}
                                        disabled={isSubmitting}
                                        onChange={() => {
                                            setFieldValue('chosenPromoProduct', String(promoProduct.id))
                                        }}
                                        className='c-radio--addition'
                                    />
                                ))}
                            </motion.div>
                        ) : null}

                        <motion.div variants={staggerFadeInUpChild}>
                            <PhoneField
                                label={t(
                                    'promotions.input.otp_phone.label',
                                    'Please enter your phone number to receive your personal activation code:'
                                )}
                                disabled={isSubmitting}
                                defaultCountry={event?.venue?.country ?? 'BE'}
                            />
                        </motion.div>

                        {status && status.loginstatus && (
                            <div>
                                <p className='u-error u-text-center'>{status.loginstatus}</p>
                            </div>
                        )}

                        <motion.div variants={staggerFadeInUpChild}>
                            <input
                                style={color && { background: color }}
                                className='c-button c-button-dark'
                                type='submit'
                                disabled={isSubmitting}
                                value={
                                    isSubmitting
                                        ? t('button.form.disabled.cta')
                                        : t('promotions.otp_phone.submit.cta', 'Request personal activation code')
                                }
                            />
                        </motion.div>
                    </motion.div>
                </Form>
            )}
        </Formik>
    )
}

export default PromoRegisterForm
