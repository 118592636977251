import classNames from 'classnames'

import './carousel.scss'

function Slide({ children, className }) {
    return (
        <div className={classNames('carousel__slide', className)}>
            <div className='carousel__slide__inner'>{children}</div>
        </div>
    )
}

export default Slide
