import AddToBasketModal from './components/AddToBasketModal'
import ApplyLoyaltyModal from './components/ApplyLoyaltyModal'
import ApplyReductionModal from './components/ApplyReductionModal'
import CompetitionModal from './components/CompetitionModal'
import ConfimModal from './components/ConfimModal'
import CreditsHowToModal from './components/CreditsHowToModal'
import ProductSuggestionModal from './components/CrossSellModal'
import CustomTippingModal from './components/CustomTippingModal'
import DialogModal from './components/DialogModal'
import DynamicModal from './components/DynamicModal'
import ErrorModal from './components/ErrorModal'
import InactivityModal from './components/InactivityModal'
import PayByQrCodeModal from './components/PayByQrCodeModal'
import ProductConfiguratorModal from './components/ProductConfiguratorModal'
import PromoModal from './components/PromoModal'
import QuantitySelectorModal from './components/QuantitySelectorModal'
import RatingModal from './components/RatingModal'
import StarnetPaymentModal from './components/StarnetPaymentModal'
import UpsellAdditionsModal from './components/UpsellAdditionsModal'
import VideoStoryModal from './components/VideoStoryModal'
import VoucherPaymentModal from './components/VoucherPaymentModal'
import ModalRoot from './ModalRoot'
import {
    ADD_TO_BASKET_MODAL,
    APPLY_LOYALTY_MODAL,
    APPLY_REDUCTION_MODAL,
    COMPETITION_MODAL,
    CONFIRM_MODAL,
    CREDITS_HOW_TO_MODAL,
    CROSSSELL_MODAL,
    CUSTOM_TIPPING_MODAL,
    DIALOG_MODAL,
    DYNAMIC_MODAL,
    ERROR_MODAL,
    INACTIVITY_MODAL,
    PAY_BY_QR_MODAL,
    PRODUCT_CONFIGURATOR_MODAL,
    PROMO_MODAL,
    QUANTITY_SELECTOR_MODAL,
    RATING_MODAL,
    STARNET_PAYMENT_MODAL,
    UPSELL_ADDITIONS_MODAL,
    VIDEO_STORY_MODAL,
    VOUCHER_PAYMENT_MODAL,
} from './modalTypes'

function Modals() {
    return (
        <ModalRoot>
            <AddToBasketModal
                canCloseByBackdrop
                position='bottom'
                type={ADD_TO_BASKET_MODAL}
            />
            <QuantitySelectorModal
                canCloseByBackdrop
                position='bottom'
                type={QUANTITY_SELECTOR_MODAL}
            />
            <ErrorModal
                canCloseByBackdrop
                type={ERROR_MODAL}
            />
            <ConfimModal
                canCloseByBackdrop
                type={CONFIRM_MODAL}
            />
            <DialogModal
                canCloseByBackdrop
                type={DIALOG_MODAL}
            />
            <RatingModal
                canCloseByBackdrop
                type={RATING_MODAL}
            />
            <ProductSuggestionModal
                position='bottom'
                type={CROSSSELL_MODAL}
            />
            <CreditsHowToModal
                canCloseByBackdrop
                type={CREDITS_HOW_TO_MODAL}
            />
            <UpsellAdditionsModal
                canCloseByBackdrop={false}
                position='bottom'
                type={UPSELL_ADDITIONS_MODAL}
            />
            <ApplyLoyaltyModal
                canCloseByBackdrop
                type={APPLY_LOYALTY_MODAL}
            />
            <ApplyReductionModal
                canCloseByBackdrop
                type={APPLY_REDUCTION_MODAL}
            />
            <StarnetPaymentModal
                canCloseByBackdrop
                position='bottom'
                type={STARNET_PAYMENT_MODAL}
            />
            <VoucherPaymentModal
                canCloseByBackdrop
                position='bottom'
                type={VOUCHER_PAYMENT_MODAL}
            />
            <DynamicModal
                canCloseByBackdrop
                position='bottom'
                type={DYNAMIC_MODAL}
            />
            <InactivityModal
                canCloseByBackdrop
                type={INACTIVITY_MODAL}
            />
            <VideoStoryModal
                canCloseByBackdrop
                type={VIDEO_STORY_MODAL}
            />
            <PayByQrCodeModal
                canCloseByBackdrop={false}
                type={PAY_BY_QR_MODAL}
            />
            <ProductConfiguratorModal
                canCloseByBackdrop
                position='bottom'
                type={PRODUCT_CONFIGURATOR_MODAL}
            />
            <PromoModal
                canCloseByBackdrop
                position='bottom'
                type={PROMO_MODAL}
            />
            <CustomTippingModal
                canCloseByBackdrop
                position='bottom'
                type={CUSTOM_TIPPING_MODAL}
            />
            <CompetitionModal
                canCloseByBackdrop
                position='bottom'
                type={COMPETITION_MODAL}
            />
        </ModalRoot>
    )
}

export default Modals
