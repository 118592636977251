import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { staggerFadeInUpContainer } from '../../constants/Animations.ts'
import { KioskContext } from '../../context/KioskContextProvider'

import './allergens.scss'

function Allergens({ children }) {
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation()

    const { kioskMode } = useContext(KioskContext)

    const onSetReveal = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div>
            <div
                onClick={onSetReveal}
                className={classNames('c-allergens--trigger', { isOpen, kioskMode })}>
                {t('allergens.trigger.cta')}
            </div>

            {isOpen || kioskMode ? (
                <motion.div
                    variants={staggerFadeInUpContainer}
                    initial='hidden'
                    animate='show'
                    className={classNames('c-allergens', { kioskMode })}>
                    {children}
                </motion.div>
            ) : null}
        </div>
    )
}

export default Allergens
