import { lazy, useContext } from 'react'

import { KioskContext } from '../context/KioskContextProvider'

const AppRoutes = lazy(async () => import('./AppRoutes'))
const KioskRoutes = lazy(async () => import('./KioskRoutes'))
const CookieMessage = lazy(async () => import('../containers/cookies/CookieMessage'))

const Routers = () => {
    const { kioskMode } = useContext(KioskContext)

    if (kioskMode) {
        return <KioskRoutes />
    }

    return (
        <>
            <AppRoutes />
            <CookieMessage />
        </>
    )
}

export default Routers
