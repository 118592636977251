import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import { useScreenHeight } from '../../../components/common/withScreenHeight'
import CrossSellProductItem from '../../../components/CrossSell/CrossSellProductItem'
import { slideInBottom } from '../../../constants/Animations.ts'

import ProductHeader from './ProductHeader'

import './modal.scss'

function CrossSellModal({ data }) {
    const { t } = useTranslation()
    const screenHeight = useScreenHeight()

    const onContinueOrder = useCallback(() => {
        data.onContinueOrder?.()
    }, [data])

    return (
        <div style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}>
            <motion.div
                className='c-modal--item c-modal--cross-sell'
                variants={slideInBottom}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <div className='c-fake-modal-backdrop'>
                    <div className='c-modal--cross-sell-content'>
                        <ProductHeader name={data.title} />

                        <div
                            className='c-modal--cross-sell-list'
                            style={{ maxHeight: `${parseInt(screenHeight * 0.9 - 220, 10)}px` }}>
                            {data.products.map((product) => (
                                <CrossSellProductItem
                                    key={product.product.id}
                                    eventId={data.event_id}
                                    product={product.product}
                                    type='cross_sell'
                                    ruleId={product.rule_id}
                                />
                            ))}
                        </div>
                    </div>

                    <motion.div className='c-modal--item-close c-modal--addtobasket-actions'>
                        <button
                            className='c-basket--order'
                            onClick={onContinueOrder}>
                            {t('upsell.button.deny')}
                        </button>
                    </motion.div>
                </div>
            </motion.div>
        </div>
    )
}

export default CrossSellModal
