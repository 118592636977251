import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaAngleLeft, FaAngleRight, FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import Addition from '../../../components/02_molecules/Addition/Addition.tsx'
import Button from '../../../components/Button/Button.tsx'
import ScrollPane from '../../../components/common/ScrollPane'
import { useScreenHeight } from '../../../components/common/withScreenHeight'
import ProgressBar from '../../../components/ProgressBar/ProgressBar'
import { slideInBottom } from '../../../constants/Animations.ts'
import { closeModal } from '../../../redux/actions/actionBuilders'

import ProductHeader from './ProductHeader'

function ProductConfiguratorModal({ data }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const screenHeight = useScreenHeight()
    const steps = data.additions.length

    const [step, setStep] = useState(data.currentIndex)
    const isLastStep = step === steps - 1

    const [mergedAdditionsValues, setMergedAdditionsValues] = useState({})

    const handleValueChange = useCallback((values) => {
        setMergedAdditionsValues((previousState) => ({ ...previousState, [values.id.toString()]: values }))
    }, [])

    useEffect(() => {
        if (Object.keys(mergedAdditionsValues).length > 0) {
            const additionsArray = Object.values(mergedAdditionsValues)
            const allAdditionGroupsValid = additionsArray.every((v) => v.isValid)
            data.onAdditionsChange({ isValid: allAdditionGroupsValid, values: additionsArray })
        }
    }, [mergedAdditionsValues, data])

    const handleNext = useCallback(() => setStep((prevStep) => prevStep + 1), [step])
    const handleBack = useCallback(() => setStep((prevStep) => prevStep - 1), [step])
    const handleDone = useCallback(() => dispatch(closeModal()), [])

    return (
        <motion.div style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}>
            <motion.div
                className='c-modal--item c-modal--productconfigurator'
                variants={slideInBottom}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <div className='c-fake-modal-backdrop'>
                    <div className='c-close-modal-header'>
                        <button
                            aria-label={t('general.button.close.label')}
                            onClick={() => dispatch(closeModal())}
                            className='c-button-close-modal-small'>
                            <FaTimes />
                        </button>
                    </div>
                    <ScrollPane
                        style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}
                        className='c-modal--item-content'>
                        <ProductHeader
                            name={data.product.name}
                            image={
                                data.product.images && data.product.images.medium ? data.product.images.medium : null
                            }
                            imageSize={data.product.image_size ? data.product.image_size : 'cover'}
                            hasAdditions
                            stickyChildren={
                                data.canNavigateSteps === true ? (
                                    <div className='steps-header'>
                                        <div className='steps-label'>
                                            <p>
                                                <span>{`${step + 1}`}</span>
                                                {`/${steps}`}
                                            </p>
                                        </div>
                                        <div className='steps-progress'>
                                            <ProgressBar progress={Math.round(((step + 1) / steps) * 100)} />
                                        </div>
                                    </div>
                                ) : null
                            }
                        />

                        {data.additions.map((addition) => (
                            <motion.div
                                style={{ display: addition.id === data.additions[step].id ? 'block' : 'none' }}
                                key={addition.id}
                                initial={{ opacity: 0, y: 90 }}
                                animate={{ opacity: 1, y: addition.id === data.additions[step].id ? 0 : 90 }}>
                                <Addition
                                    isOrderable
                                    initialValues={
                                        data.currentValues?.values?.find(
                                            ({ id }) => id.toString() === addition.id.toString()
                                        )?.values ?? null
                                    }
                                    addition={addition}
                                    onValues={handleValueChange}
                                />
                            </motion.div>
                        ))}
                    </ScrollPane>

                    {data.canNavigateSteps === false ? (
                        <motion.div className='c-modal--item-close c-modal--addtobasket-actions c-modal--productconfigurator-controls'>
                            <Button
                                type='button'
                                variant='dark'
                                onClick={handleDone}
                                label={t('button.confirm.cta')}
                                disabled={mergedAdditionsValues?.[data.additions?.[step]?.id]?.isValid === false}
                            />
                        </motion.div>
                    ) : (
                        <motion.div className='c-modal--item-close c-modal--addtobasket-actions c-modal--productconfigurator-controls'>
                            {step > 0 ? (
                                <Button
                                    type='button'
                                    variant='ghost'
                                    colorVariant='dark'
                                    label={t('button.previous')}
                                    onClick={handleBack}
                                    leftChild={<FaAngleLeft />}
                                />
                            ) : (
                                <div />
                            )}
                            {!isLastStep ? (
                                <Button
                                    type='button'
                                    variant='dark'
                                    onClick={handleNext}
                                    label={t('button.next')}
                                    rightChild={<FaAngleRight />}
                                    disabled={mergedAdditionsValues?.[data.additions?.[step]?.id]?.isValid === false}
                                />
                            ) : null}

                            {isLastStep ? (
                                <Button
                                    type='button'
                                    variant='dark'
                                    onClick={handleDone}
                                    label={t('button.confirm.cta')}
                                    disabled={mergedAdditionsValues?.[data.additions?.[step]?.id]?.isValid === false}
                                />
                            ) : null}
                        </motion.div>
                    )}
                </div>
            </motion.div>
        </motion.div>
    )
}

export default ProductConfiguratorModal
