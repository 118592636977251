/* eslint-disable react/display-name */
import DrawerMenuContextProvider from './DrawerMenuContextProvider'
import EventContextProvider from './EventContextProvider'
import EventGroupContextProvider from './EventGroupContextProvider'
import HeaderContextProvider from './HeaderContext'
import KioskContextProvider from './KioskContextProvider'
import PlaceContextProvider from './PlaceContextProvider'
import ThemeContextProvider from './ThemeContextProvider'
import UserAndTokenContextProvider from './UserAndTokenContextProvider'

const providers = [
    HeaderContextProvider,
    ThemeContextProvider,
    UserAndTokenContextProvider,
    DrawerMenuContextProvider,
    PlaceContextProvider,
    EventGroupContextProvider,
    EventContextProvider,
    KioskContextProvider,
]

const ContextProviders = providers.reduce(
    (AccumulatedProviders, CurrentProvider) =>
        function ({ children }) {
            return (
                <AccumulatedProviders>
                    <CurrentProvider>{children}</CurrentProvider>
                </AccumulatedProviders>
            )
        },
    ({ children }) => <>{children}</>
)

export default ContextProviders
