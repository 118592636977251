import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import Rating from '../../../components/Rating/Rating.tsx'
import { popIn } from '../../../constants/Animations.ts'
import { closeModal } from '../../../redux/actions/actionBuilders'
import BillySDK from '../../../sdk/sdk'

import './modal.scss'

function RatingModal({ data }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const vote = useRef()

    // we have a rating, not submitted message
    const onRating = useCallback((score) => {
        vote.current = score
    }, [])

    // we have a rating and a message after submit
    const onSubmit = useCallback((score) => {
        vote.current = score
        dispatch(closeModal())
    }, [])

    const postVote = useCallback(() => {
        BillySDK.rating(data.venueId, vote.current)
    }, [])

    // set default value and post user review on unmount
    useEffect(() => {
        vote.current = { score: -1, message: null }
        return () => postVote()
    }, [])

    return (
        <motion.div
            className='c-modal--item'
            variants={popIn}
            initial='hidden'
            animate='visible'
            exit='exit'>
            <div className='c-modal--item-content c-modal--rating'>
                <h3 className='c-modal--item-name'>{t('modal.rating.title', { venue: data?.venueName })}</h3>
                {data?.message && data.message()}

                <Rating
                    placeId={data.placeId}
                    onRating={onRating}
                    onSubmit={onSubmit}
                />
            </div>
        </motion.div>
    )
}

export default RatingModal
