import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import { useScreenHeight } from '../../../components/common/withScreenHeight'
import { slideInBottom } from '../../../constants/Animations.ts'
import { closeModal } from '../../../redux/actions/actionBuilders'

function DynamicModal({ data }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const screenHeight = useScreenHeight()
    return (
        <motion.div
            layout='position'
            style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}>
            <motion.div
                className='c-modal--item c-modal--dynamic'
                variants={slideInBottom}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <div className='c-fake-modal-backdrop'>
                    <div className='c-close-modal-header'>
                        <button
                            aria-label={t('general.button.close.label')}
                            onClick={() => dispatch(closeModal())}
                            className='c-button-close-modal-small'>
                            <FaTimes />
                        </button>
                    </div>
                    <motion.div className='c-modal--item-content'>{data.content ? data.content : null}</motion.div>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default DynamicModal
