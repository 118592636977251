import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import Carousel from '../../../components/carousel/Carousel'
import { withScreenHeight } from '../../../components/common/withScreenHeight'
import { slideInBottom } from '../../../constants/Animations.ts'
import * as actionBuilders from '../../../redux/actions/actionBuilders'
import withRedux from '../../../redux/withRedux'
import CreditsTutorialSlide from '../../tutorial/credits/CreditsTutorialSlide'

import './modal.scss'

const mapStateToProps = () => ({})

class CreditsHowToModal extends Component {
    constructor(props) {
        super(props)
        this.onConfirm = this.onConfirm.bind(this)
    }

    onConfirm() {
        this.props.closeModal()
        if (this.props.data.onConfirm) this.props.data.onConfirm()
    }

    render() {
        return (
            <div style={{ maxHeight: `${parseInt(this.props.screenHeight * 0.9, 10)}px` }}>
                <motion.div
                    className='c-modal--item c-modal--addtobasket-item'
                    variants={slideInBottom}
                    initial='hidden'
                    animate='visible'
                    exit='exit'>
                    <div className='c-fake-modal-backdrop'>
                        <Carousel>
                            <Carousel.Slide>
                                <CreditsTutorialSlide />
                            </Carousel.Slide>

                            <Carousel.Slide>
                                <CreditsTutorialSlide />
                            </Carousel.Slide>

                            <Carousel.Slide>
                                <CreditsTutorialSlide />
                            </Carousel.Slide>
                        </Carousel>
                    </div>
                </motion.div>
            </div>
        )
    }
}

export default withTranslation()(withRedux(withScreenHeight(CreditsHowToModal), mapStateToProps, actionBuilders))
