import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import Additions from '../../../components/03_organisms/Additions/Additions.tsx'
import ScrollPane from '../../../components/common/ScrollPane'
import { useScreenHeight } from '../../../components/common/withScreenHeight'
import Recommendations from '../../../components/CrossSell/Recommendations'
import ProductConfigurator from '../../../components/ProductConfigurator/ProductConfigurator'
import PromoRecommendations from '../../../components/PromoRecommendation/PromoRecommendations'
import QuantitySelector from '../../../components/QuantitySelector/QuantitySelector.tsx'
import { slideInBottom } from '../../../constants/Animations.ts'
import useValidateAdditions from '../../../hooks/useValidateAdditions.ts'
import { addToBasket, closeModal } from '../../../redux/actions/actionBuilders'

import ProductHeader from './ProductHeader'

import './modal.scss'

function AddToBasketModal({ data }) {
    const { t } = useTranslation()
    const screenHeight = useScreenHeight()
    const dispatch = useDispatch()

    const hasAdditions = data.product.additions.length > 0
    const additionsConfigurator = data.product.has_stepped_additions

    const [selectedRecommendations, setSelectedRecommendations] = useState([])

    const [amount, setAmount] = useState(data.amount)

    const {
        validateAdditions,
        totalAdditionsPrice,
        selectedAdditions,
        isValid: canAddProduct,
    } = useValidateAdditions(data.product?.additions)

    const onUpdateRecommendations = useCallback(
        ({ product, amount }) => {
            if (product === null || product === undefined) return

            const existingItemIndex = selectedRecommendations.findIndex((item) => item.id === product.id)

            // Item should be removed
            if (amount === 0 && existingItemIndex !== -1) {
                const updatedItems = selectedRecommendations.filter((item) => item.id !== product.id)
                setSelectedRecommendations(updatedItems)
                return
            }

            // Amount of selection should be updated
            if (amount > 0 && existingItemIndex !== -1) {
                const updatedItems = [...selectedRecommendations]
                updatedItems[existingItemIndex].amount = amount
                setSelectedRecommendations(updatedItems)
                return
            }

            // Add the new recommendation to the selection Array
            setSelectedRecommendations((prevSelection) => [...prevSelection, { ...product, amount: amount || 0 }])
        },
        [selectedRecommendations]
    )

    const onUpdateAmount = useCallback((adjuster) => {
        setAmount((prevState) => Math.max(prevState + adjuster, 1))
    }, [])

    const onUpdateAdditions = useCallback(
        (values) => {
            validateAdditions(values)
        },
        [validateAdditions]
    )

    const onClose = useCallback(() => {
        dispatch(closeModal())
    }, [])

    const onConfirm = useCallback(() => {
        // Add product to basket
        dispatch(
            addToBasket({
                ...data.product,
                event_id: data.eventId,
                eventName: data.eventName,
                groupId: data.groupId,
                additions: { ...selectedAdditions },
                has_additions: !!Object.keys({ ...selectedAdditions }).length,
                totalAdditionsPrice,
                amount,
            })
        )

        // Add all selected recommendations to basket
        selectedRecommendations.forEach((recommendation) => {
            dispatch(
                addToBasket({
                    ...recommendation,
                    event_id: recommendation.event_id ? recommendation.event_id : data.eventId,
                    eventName: data.eventName,
                    groupId: data.groupId,
                    is_recommendation: true,
                    parent_product_id: data.product.id,
                    type: 'recommendation',
                    type_reference_id: data.product.id,
                    has_additions: false,
                    totalAdditionsPrice: 0,
                    amount: amount * (recommendation.amount ?? 1),
                })
            )
        })

        if (data.onConfirm) data.onConfirm()
        dispatch(closeModal())
    }, [data, amount, totalAdditionsPrice, selectedAdditions, selectedRecommendations, data, addToBasket, dispatch])

    if (data?.product) {
        return (
            <div style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}>
                <motion.div
                    className='c-modal--item c-modal--addtobasket-item'
                    variants={slideInBottom}
                    initial='hidden'
                    animate='visible'
                    exit='exit'>
                    <div className='c-fake-modal-backdrop'>
                        <div className='c-close-modal-header'>
                            <button
                                aria-label={t('general.button.close.label')}
                                onClick={onClose}
                                className='c-button-close-modal-small'>
                                <FaTimes />
                            </button>
                        </div>

                        <ScrollPane
                            style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}
                            className='c-modal--item-content'>
                            <ProductHeader
                                name={data.product.name}
                                // show description with header if no additions
                                description={
                                    data.product.additions &&
                                    data.product.additions.length === 0 &&
                                    data.product.description
                                }
                                image={
                                    data.product.images && data.product.images.medium
                                        ? data.product.images.medium
                                        : null
                                }
                                imageSize={data.product.image_size ? data.product.image_size : 'cover'}
                                allergens={data.product.allergens}
                                hasAdditions={hasAdditions}
                            />

                            {data.isOrderable === false ? (
                                <div className='c-modal--item-not-orderable'>
                                    <p>{data.notOrderableReason ?? t('product.not_orderable.description')}</p>
                                </div>
                            ) : null}

                            {
                                // show description beneath header if product has additions
                                data.product.description && data.product.additions && hasAdditions && (
                                    <div className='c-modal--item-description'>
                                        <p>{data.product.description}</p>
                                    </div>
                                )
                            }

                            {data.product.additions &&
                            data.menuOnly === false &&
                            data.isOrderable === true &&
                            // productConfigurated === true &&
                            hasAdditions === true &&
                            additionsConfigurator === true ? (
                                <ProductConfigurator
                                    product={data.product}
                                    orderable={data.isOrderable}
                                    selectedAdditions={selectedAdditions}
                                    onAdditionsChange={onUpdateAdditions}
                                />
                            ) : null}

                            {data.product.additions && hasAdditions && additionsConfigurator === false ? (
                                <Additions
                                    isOrderable={data.isOrderable ?? false}
                                    additions={data.product.additions ?? []}
                                    onAdditionsChange={onUpdateAdditions}
                                />
                            ) : null}

                            {data.isOrderable === false || data.menuOnly ? null : (
                                <>
                                    <Recommendations
                                        recommendations={data.product.recommendations}
                                        onRecommendationClick={onUpdateRecommendations}
                                        selectedRecommendations={selectedRecommendations}
                                    />
                                    <PromoRecommendations
                                        product={data.product}
                                        promotions={data.promotions}
                                    />
                                </>
                            )}
                        </ScrollPane>

                        <motion.div className='c-modal--item-close c-modal--addtobasket-actions'>
                            {
                                // eslint-disable-next-line no-nested-ternary
                                data.isOrderable === false || data.menuOnly || data.maxOrderableAmount === 0 ? (
                                    <>
                                        {data.maxOrderableAmount === 0 ? (
                                            <p className='c-quantityselector-maxorderedmessage'>
                                                {t('product.maximum_in_basket_reached.label')}
                                            </p>
                                        ) : null}
                                        <button
                                            className='c-basket--order'
                                            onClick={onClose}>
                                            {t('button.close.cta')}
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <div className='c-quantityselector-wrapper'>
                                            <QuantitySelector
                                                type='small'
                                                amount={amount}
                                                range={{
                                                    min: 1,
                                                    max: data.maxOrderableAmount,
                                                }}
                                                onQuantityChange={onUpdateAmount}
                                            />
                                            {data.maxOrderableAmount <= 20 ? (
                                                <p className='c-quantityselector-maxorderablelabel'>
                                                    {t('product.maximum_allowed_in_basket.label', {
                                                        amount: data.maxOrderableAmount,
                                                    })}
                                                </p>
                                            ) : null}
                                        </div>

                                        <button
                                            className='c-basket--order'
                                            onClick={onConfirm}
                                            disabled={!canAddProduct}>
                                            {t('button.add_to_basket.cta')}
                                        </button>
                                    </>
                                )
                            }
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        )
    }

    return null
}

export default AddToBasketModal
