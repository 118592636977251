import UserType from '../Enums/UserType'
import IAddress from '../Interfaces/IAddress'

interface IUserData {
    id: string
    type: UserType
    firstname: string
    name: string
    email: string
    email_unverified: boolean
    language: string
    phone: string
    phone_verified: boolean
    gender: string
    is_verified: boolean
    has_agreed: boolean
    email_verified_at: Date | string
    address: IAddress
}

class User {
    id: string

    type: UserType

    firstname: string

    name: string

    email: string

    emailUnverified: boolean

    language: string

    phone: string

    phoneVerified: boolean

    gender: string

    isVerified: boolean

    hasAgreed: boolean

    emailVerifiedAt: Date | string

    address: IAddress

    constructor(data: IUserData) {
        this.id = data.id
        this.type = data.type
        this.firstname = data.firstname
        this.name = data.name
        this.email = data.email
        this.emailUnverified = data.email_unverified
        this.language = data.language
        this.phone = data.phone
        this.phoneVerified = data.phone_verified
        this.gender = data.gender
        this.isVerified = data.is_verified
        this.hasAgreed = data.has_agreed
        this.emailVerifiedAt = data.email_verified_at
        this.address = data.address
    }
}

export default User
