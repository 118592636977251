import i18n from '../../i18next-config'

interface IAllergen {
    icon: string
    color: string
    id: string
    display_name: string
}

export let ALLERGENS: Array<IAllergen>

i18n.on('initialized languageChanged', () => {
    ALLERGENS = [
        {
            icon: 'egg',
            color: '#ffa32f',
            id: 'eggs',
            display_name: i18n.t('allergens.eggs'),
        },

        {
            icon: 'soybeans',
            color: '#81913c',
            id: 'soy',
            display_name: i18n.t('allergens.soy'),
        },

        {
            icon: 'milk',
            color: '#78bab5',
            id: 'milk',
            display_name: i18n.t('allergens.milk'),
        },

        {
            icon: 'crustaceans',
            color: '#df8266',
            id: 'crustaceans', // Schaaldieren
            display_name: i18n.t('allergens.crustaceans'),
        },

        {
            icon: 'mustard',
            color: '#b48146',
            id: 'mustard',
            display_name: i18n.t('allergens.mustard'),
        },

        {
            icon: 'fish',
            color: '#0c8389',
            id: 'fish',
            display_name: i18n.t('allergens.fish'),
        },

        {
            icon: 'molluscs',
            color: '#38aeba',
            id: 'molluscs', // Weekdieren
            display_name: i18n.t('allergens.molluscs'),
        },

        {
            icon: 'gluten',
            color: '#b99f00',
            id: 'gluten',
            display_name: i18n.t('allergens.gluten'),
        },

        {
            icon: 'sesame',
            color: '#edb04d',
            id: 'sesame',
            display_name: i18n.t('allergens.sesame'),
        },

        {
            icon: 'sulphurdioxide',
            color: '#1e8e7e',
            id: 'sulphites',
            display_name: i18n.t('allergens.sulphites'),
        },

        {
            icon: 'peanuts',
            color: '#d1a171',
            id: 'peanuts',
            display_name: i18n.t('allergens.peanuts'),
        },

        {
            icon: 'treenuts',
            color: '#844e36',
            id: 'nuts',
            display_name: i18n.t('allergens.nuts'),
        },

        {
            icon: 'alcohol',
            color: '#692d9e',
            id: 'alcohol',
            display_name: i18n.t('allergens.alcohol'),
        },

        {
            icon: 'lupin',
            color: '#9833d3',
            id: 'lupin',
            display_name: i18n.t('allergens.lupin'),
        },

        {
            icon: 'celery',
            color: '#b5c652',
            id: 'celery',
            display_name: i18n.t('allergens.celery'),
        },

        // Extra gluten
        {
            icon: 'gluten',
            color: '#b99f00',
            id: 'barley',
            display_name: i18n.t('allergens.gluten.barley'),
        },

        {
            icon: 'gluten',
            color: '#b99f00',
            id: 'kamut',
            display_name: i18n.t('allergens.gluten.kamut'),
        },

        {
            icon: 'gluten',
            color: '#b99f00',
            id: 'oats',
            display_name: i18n.t('allergens.gluten.oats'),
        },

        {
            icon: 'gluten',
            color: '#b99f00',
            id: 'rye',
            display_name: i18n.t('allergens.gluten.rye'),
        },

        {
            icon: 'gluten',
            color: '#b99f00',
            id: 'spelt',
            display_name: i18n.t('allergens.gluten.spelt'),
        },

        // Extra nuts
        {
            icon: 'treenuts',
            color: '#844e36',
            id: 'almonds',
            display_name: i18n.t('allergens.nuts.almonds'),
        },

        {
            icon: 'treenuts',
            color: '#844e36',
            id: 'brazilnuts',
            display_name: i18n.t('allergens.nuts.brazilnuts'),
        },

        {
            icon: 'treenuts',
            color: '#844e36',
            id: 'cashew',
            display_name: i18n.t('allergens.nuts.cashew'),
        },

        {
            icon: 'treenuts',
            color: '#844e36',
            id: 'hazelnuts',
            display_name: i18n.t('allergens.nuts.hazelnuts'),
        },

        {
            icon: 'treenuts',
            color: '#844e36',
            id: 'macadamia',
            display_name: i18n.t('allergens.nuts.macadamia'),
        },

        {
            icon: 'treenuts',
            color: '#844e36',
            id: 'pecan',
            display_name: i18n.t('allergens.nuts.pecan'),
        },

        {
            icon: 'treenuts',
            color: '#844e36',
            id: 'pistachios',
            display_name: i18n.t('allergens.nuts.pistachios'),
        },

        {
            icon: 'treenuts',
            color: '#844e36',
            id: 'walnuts',
            display_name: i18n.t('allergens.nuts.walnuts'),
        },

        {
            icon: 'milk',
            color: '#78bab5',
            id: 'dairy',
            display_name: i18n.t('allergens.dairy'),
        },

        {
            icon: 'sugaryproduct',
            color: '#bcaf61',
            id: 'sugaryproduct',
            display_name: i18n.t('allergens.sugaryproduct'),
        },
    ]
})
