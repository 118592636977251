import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { PRODUCT_CONFIGURATOR_MODAL } from '../../containers/modal/modalTypes'
import { openModal } from '../../redux/actions/actionBuilders'

import ProductConfiguratorItem from './ProductConfiguratorItem'

import './productconfigurator.scss'

function ProductConfigurator({ product, requestStartConfigurator, selectedAdditions, onAdditionsChange }) {
    const dispatch = useDispatch()

    const [currentValues, setCurrentValues] = useState()

    useEffect(() => {
        onAdditionChangeRequest(0, null, true)
    }, [])

    const onAdditionChangeRequest = useCallback(
        (additionId, currVals, canNavigateSteps) => {
            const currentIndex = product.additions.findIndex((item) => item.id === additionId)
            dispatch(
                openModal({
                    type: PRODUCT_CONFIGURATOR_MODAL,
                    data: {
                        product,
                        currentValues: currVals,
                        currentIndex: currentIndex !== -1 ? currentIndex : 0,
                        canNavigateSteps,
                        additions: product.additions,
                        onAdditionsChange: (values) => {
                            setCurrentValues((previous) => ({ ...previous, ...values }))
                            onAdditionsChange(values)
                        },
                    },
                })
            )
        },
        [onAdditionsChange, dispatch, openModal]
    )

    const checkIsValid = useCallback(
        (id) => currentValues?.values.find((value) => value.id.toString() === id)?.isValid ?? false,
        [product.additions, currentValues]
    )

    return (
        <div className='c-product-configurator'>
            <div className='c-product-configurator--inner'>
                {product.additions.map((addition) => (
                    <ProductConfiguratorItem
                        disabled={requestStartConfigurator === false}
                        name={addition.name}
                        key={addition.id}
                        index={addition.id}
                        addition={addition}
                        isValid={checkIsValid(addition.id.toString())}
                        currentValues={selectedAdditions?.[addition.id.toString()]}
                        onAdditionChangeRequest={(id) => onAdditionChangeRequest(id, currentValues, false)}
                    />
                ))}
            </div>
        </div>
    )
}

export default ProductConfigurator
