export const QUANTITY_SELECTOR_MODAL = '@modaltypes/QUANTITY_SELECTOR_MODAL'
export const ADD_TO_BASKET_MODAL = '@modaltypes/ADD_TO_BASKET_MODAL'
export const ERROR_MODAL = '@modaltypes/ERROR_MODAL'
export const CONFIRM_MODAL = '@modaltypes/CONFIRM_MODAL'
export const DIALOG_MODAL = '@modaltypes/DIALOGMODAL'
export const RATING_MODAL = '@modaltypes/RATINGMODAL'
export const CROSSSELL_MODAL = '@modaltypes/CROSSSELL_MODAL'
export const CREDITS_HOW_TO_MODAL = '@modaltypes/CREDITS_HOW_TO_MODAL'
export const UPSELL_ADDITIONS_MODAL = '@modaltypes/UPSELL_ADDITIONS_MODAL'
export const APPLY_LOYALTY_MODAL = '@modaltypes/APPLY_LOYALTY_MODAL'
export const APPLY_REDUCTION_MODAL = '@modaltypes/APPLY_REDUCTION_MODAL'
export const STARNET_PAYMENT_MODAL = '@modaltypes/STARNET_PAYMENT_MODAL'
export const VOUCHER_PAYMENT_MODAL = '@modaltypes/VOUCHER_PAYMENT_MODAL'
export const DYNAMIC_MODAL = '@modaltypes/DYNAMIC_MODAL'
export const INACTIVITY_MODAL = '@modaltypes/INACTIVITY_MODAL'
export const VIDEO_STORY_MODAL = '@modaltypes/VIDEO_STORY_MODAL'
export const PAY_BY_QR_MODAL = '@modaltypes/PAY_BY_QR_MODAL'
export const PROMO_MODAL = '@modaltypes/PROMO_MODAL'
export const PRODUCT_CONFIGURATOR_MODAL = '@modaltypes/PRODUCT_CONFIGURATOR_MODAL'
export const CUSTOM_TIPPING_MODAL = '@modaltypes/CUSTOM_TIPPING_MODAL'
export const COMPETITION_MODAL = '@modaltypes/COMPETITION_MODAL'
