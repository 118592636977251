import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    BiCookie,
    BiFile,
    BiHeart,
    BiHelpCircle,
    BiLogOutCircle,
    BiReceipt,
    BiSpreadsheet,
    BiUserCircle,
    BiWallet,
} from 'react-icons/bi'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { captureException, withScope } from '@sentry/react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import LanguageSelector from '../../components/languageselector/LanguageSelector'
import Spinner from '../../components/spinners/Spinner'
import { menuAnimation } from '../../constants/Animations.ts'
import { BrandContext } from '../../context/BrandContextProvider.tsx'
import { DrawerMenuContext } from '../../context/DrawerMenuContextProvider'
import { KioskContext } from '../../context/KioskContextProvider'
import { UserContext } from '../../context/UserAndTokenContextProvider'
import usePendingOrder from '../../hooks/usePendingOrder'
import { openCookiesModal, removeAllLoyalty, updateTokens } from '../../redux/actions/actionBuilders'
import BillySDK from '../../sdk/sdk'
import RoutePath from '../../structures/Enums/RoutePath.enum.ts'
import UserType from '../../structures/Enums/UserType.ts'

import DrawerMenuItem from './DrawerMenuItem'

import './settings.scss'

function DrawerMenu() {
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useDispatch()
    const { user, updateUser } = useContext(UserContext)
    const { drawerMenuOpen, setDrawerMenuOpen } = useContext(DrawerMenuContext)
    const [isLoading, setIsLoading] = useState(false)
    const { brand, isBees, isBilly, isQbh } = useContext(BrandContext)
    const { kioskMode } = useContext(KioskContext)
    const { cancelAndRemovePendingOrder } = usePendingOrder()

    const onLogoutClick = async () => {
        setIsLoading(true)
        await cancelAndRemovePendingOrder()
        BillySDK.logout()
            .then(async () => {
                dispatch(
                    updateTokens({
                        access_token: undefined,
                    })
                )
                dispatch(removeAllLoyalty())
                updateUser(undefined)
            })
            .catch(() => {
                withScope((scope) => {
                    scope.setUser({ id: user.id })
                    captureException(new Error('BillySDK.logout error'))
                })
            })
            .finally(() => {
                setDrawerMenuOpen(false)
                setIsLoading(false)
            })
    }

    const onCookiePrompt = useCallback(() => {
        setDrawerMenuOpen(false)
        dispatch(openCookiesModal())
    }, [setDrawerMenuOpen])

    if (isLoading) {
        return (
            <AnimatePresence
                initial
                exitBeforeEnter>
                {drawerMenuOpen && (
                    <motion.div
                        className='c-settings'
                        variants={menuAnimation}
                        initial='hidden'
                        animate='visible'
                        exit='exit'>
                        <div style={{ height: '50vh' }}>
                            <Spinner />
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        )
    }

    if (kioskMode === true) {
        return (
            <AnimatePresence>
                {drawerMenuOpen ? (
                    <motion.div
                        className={classNames('c-settings', { 'c-settings-kiosk': kioskMode })}
                        variants={menuAnimation}
                        initial='hidden'
                        animate='visible'
                        exit='exit'>
                        <div className='c-settings-scroller'>
                            <LanguageSelector onLanguageChange={() => setDrawerMenuOpen(!drawerMenuOpen)} />
                        </div>
                    </motion.div>
                ) : null}
            </AnimatePresence>
        )
    }

    return (
        <AnimatePresence>
            {drawerMenuOpen ? (
                <>
                    <motion.div
                        onClick={() => setDrawerMenuOpen(false)}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className='c-settings-overlayer-desktop'
                    />
                    <motion.div
                        className='c-settings'
                        variants={menuAnimation}
                        initial='hidden'
                        animate='visible'
                        exit='exit'>
                        <div className='c-settings-scroller'>
                            {user ? (
                                <div className='c-settings--header c-settings--header--loggedin'>
                                    {user.type !== UserType.USER ? (
                                        <div className='c-settings--header--user'>
                                            <div className='c-settings--header-user-picture'>
                                                <img
                                                    src={brand.theme.logo.small}
                                                    alt={brand.brandName}
                                                />
                                            </div>
                                            <div className='c-settings--header-user-data'>
                                                <p>{`${user.firstname}`}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <Link
                                            className='c-settings--header-user-link'
                                            to={RoutePath.PROFILE}
                                            state={{
                                                from: location.pathname,
                                            }}>
                                            <div className='c-settings--header--user'>
                                                <div className='c-settings--header-user-picture'>
                                                    <img
                                                        src={brand.theme.logo.small}
                                                        alt={brand.brandName}
                                                    />
                                                </div>
                                                <div className='c-settings--header-user-data'>
                                                    <p>{`${user.firstname} ${user.name}`}</p>
                                                    <p className='user-email'>{user.email}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    )}
                                </div>
                            ) : (
                                <div className='c-settings--group'>
                                    <DrawerMenuItem
                                        label={t(
                                            isBilly
                                                ? 'settings.item.billy_account'
                                                : `${brand.identifier}.settings.item.billy_account`
                                        )}
                                        icon={<BiUserCircle />}
                                        link={`${RoutePath.LOGIN_FIRST}?redirect=${location.pathname}`}
                                    />
                                </div>
                            )}

                            {user ? (
                                <div className='c-settings--group'>
                                    <DrawerMenuItem
                                        label={t('settings.item.my_orders')}
                                        icon={<BiReceipt />}
                                        link='/orders'
                                    />

                                    {user.type === UserType.USER ? (
                                        <>
                                            <DrawerMenuItem
                                                label={t('settings.item.my_profile')}
                                                icon={<BiUserCircle />}
                                                link='/profile'
                                            />

                                            <DrawerMenuItem
                                                label={t('settings.item.my_wallet')}
                                                icon={<BiWallet />}
                                                link='/credits'
                                                hidden={!isBilly}
                                            />
                                        </>
                                    ) : null}
                                </div>
                            ) : null}

                            {isBilly ? (
                                <div className='c-settings--group'>
                                    <DrawerMenuItem
                                        link='https://support.orderbilly.com/'
                                        label={t('settings.item.help')}
                                        icon={<BiHelpCircle />}
                                        hidden={!isBilly}
                                    />

                                    <DrawerMenuItem
                                        hidden={isBees}
                                        handleClick={onCookiePrompt}
                                        label={t('settings.item.cookies')}
                                        icon={<BiCookie />}
                                    />
                                </div>
                            ) : null}

                            <div className='c-settings--group'>
                                <DrawerMenuItem
                                    link='/legal/terms'
                                    label={t('settings.item.terms_and_conditions')}
                                    icon={<BiFile />}
                                    hidden={isQbh}
                                />
                                <DrawerMenuItem
                                    link={
                                        isQbh
                                            ? 'https://privacy.dpgmedia.be/nl/document/privacy-policy'
                                            : '/legal/privacy'
                                    }
                                    label={t('settings.item.privacy_policy')}
                                    icon={<BiSpreadsheet />}
                                />
                                <DrawerMenuItem
                                    link='https://meet.orderbilly.com/'
                                    label={t('settings.item.billy_in_your_venue')}
                                    icon={<BiHeart />}
                                    hidden={!isBilly}
                                />
                            </div>

                            <div className='c-settings--group'>
                                <LanguageSelector onLanguageChange={() => setDrawerMenuOpen(!drawerMenuOpen)} />
                            </div>

                            {user ? (
                                <div className='c-settings--group'>
                                    <DrawerMenuItem
                                        label={t('settings.item.logout')}
                                        icon={<BiLogOutCircle />}
                                        handleClick={() => onLogoutClick()}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </motion.div>
                </>
            ) : null}
        </AnimatePresence>
    )
}

export default DrawerMenu
