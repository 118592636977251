import { useContext } from 'react'
import { FaAngleLeft } from 'react-icons/fa'
import { motion } from 'framer-motion'

import { DrawerMenuContext } from '../../context/DrawerMenuContextProvider'
import { KioskContext } from '../../context/KioskContextProvider'
import Button from '../Button/Button.tsx'

function Backbutton({ handleClick, disabled = false }) {
    const { drawerMenuOpen } = useContext(DrawerMenuContext)
    const { kioskMode } = useContext(KioskContext)

    if (drawerMenuOpen) {
        return (
            <div style={{ minWidth: kioskMode ? '150px' : '50px' }}>
                <span className='c-header--icon' />
            </div>
        )
    }

    if (kioskMode) {
        return (
            <motion.div
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}>
                <Button
                    disabled={disabled}
                    className='c-header-backbutton touch-action-none'
                    label='Back'
                    leftChild={<FaAngleLeft />}
                    onClick={disabled === false ? handleClick : null}
                />
            </motion.div>
        )
    }

    return (
        <span
            className='c-header--icon'
            onClick={disabled === false ? handleClick : null}>
            <FaAngleLeft />
        </span>
    )
}

export default Backbutton
