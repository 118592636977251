import { motion } from 'framer-motion'

import { useScreenHeight } from '../../../components/common/withScreenHeight'
import StoryVideo from '../../../components/StoryVideo/StoryVideo'
import { popIn } from '../../../constants/Animations.ts'

import './modal.scss'

function VideoStoryModal({ data }) {
    const screenHeight = useScreenHeight()

    return (
        <div style={{ maxHeight: `${parseInt(screenHeight * 0.7, 10)}px` }}>
            <motion.div
                className='c-modal--item c-modal--item-story'
                variants={popIn}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <StoryVideo url={data?.videoUrl} />
            </motion.div>
        </div>
    )
}

export default VideoStoryModal
